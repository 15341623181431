<template>
  <div v-if="modalVisibale" class="studentComment flex_v flex1">
    <div class="title">
      <p>Feedback</p>
      <!--
      <i @click="hidecomment"></i>
      -->
      <el-button @click="hidecomment" type="text" icon="el-icon-close" class="el-dialog__headerbtn"></el-button>
    </div>
    <div class="studentCommentList flex_v flex1">
      <template v-for="(item, index) in commentList">
        <div class="feeditem" v-if="item.otext || ['text', 'choice', 'comment'].includes(item.otype)" :key="index.toString()">
          <p class="itemtile">Slide {{ getIndex(item.page) }}</p>
          <div :class="`readed ${unreadStudentCommentIds.includes(item._id) ? 'unreadborder' : ''}`">
            <div class="feedinner">
              <div class="rightcontent">
                <div v-show="!slidesVisiable[index]">
                  <div class="right-answer" v-if="item.point">
                    <RemarkImage :item="{...item, content: item.otext}" :noSync="true" />
                  </div>
                  <div class="right-answer" v-else-if="item.otype === 'draw'">
                    <div class="pptimage">
                      <base64image :url="Fn.hashToUrl(getPageById(item.page).pic || getPageById(item.page).url)">
                        <base64image :url="item.otext" />
                      </base64image>
                    </div>
                  </div>
                  <div class="right-answer" v-else-if="isImage(item)">
                    <div class="pptimage">
                      <base64image :url="item.otext" />
                    </div>
                  </div>
                  <div class="right-answer" :class="{'deleted-response' : !item.otext}" v-else-if="['text', 'choice'].includes(item.otype)">{{ item.otext || 'Deleted Response'}}</div>
                  <div class="right-answer" v-else-if="getIndexOf(item.otext) === 'file' || item.otype === 'media'">
                    <dash-right-media-item :item="{content: item.otext}" />
                  </div>
                  <div class="right-answer" v-else-if="getIndexOf(item.otext, '.mp3') > -1">
                    <div class="pptimage">
                      <div style="width: 80%">
                        <audio-player :url="item.otext" />
                      </div>
                    </div>
                  </div>
                  <div class="right-answer" v-else-if="getIndexOf(item.otext, '.webm') > -1">
                    <div class="pptimage">
                      <VideoPlayer controlslist="nodownload" :controls="true" :src="item.otext" style="width: 80%" />
                    </div>
                  </div>
                  <div class="right-answer" v-else-if="Array.isArray(item.otext)">
                    <p v-for="(text, index) in item.otext" :key="index">{{ text }}</p>
                  </div>
                  <div class="right-answer" v-else>{{ item.otext }}</div>
                </div>
                <template v-if="getUrl(item.page)">
                  <div class="right-answer pptitemouter" v-show="slidesVisiable[index]">
                    <div class="pptitem">
                      <div class="pptimage" :style="`background-image:url(${Fn.hashToUrl(getUrl(item.page))})`"></div>
                    </div>
                    <div class="stpage">{{ getIndex(item.page) }} / {{ getPageNum }}</div>
                  </div>
                </template>
                <el-tooltip v-if="getButtonVis(item.page)" class="item" effect="dark" :content="slidesVisiable[index] ? 'Check answer' : 'Check slide'" placement="top-start">
                  <div class="rightbutton" :class="slidesVisiable[index] ? 'qa' : 'slideback'" @click="setVis(index)"></div>
                </el-tooltip>
              </div>
              <div class="border-line"></div>
              <div class="rightcomment">
                <studentInfo :data="{uid: item.from, last: item.updatedAt}" />
                <div class="rightcommentmediadetail" v-if="item.commentType === 'video' || item.commentType === 'audio'">
                  <VideoPlayer
                    v-if="item.commentType === 'video'"
                    preload="meta"
                    controlslist="nodownload"
                    :controls="true"
                    :src="item.data.value"
                    style="width: 80%" />
                  <div v-else-if="item.commentType === 'audio'" style="width: 80%">
                    <audio-player :url="item.otext" />
                  </div>
                </div>
                <div class="rightcommenttextdetail" v-else>{{ item.review }}</div>
              </div>
            </div>
            <div v-if="item._id && unreadStudentCommentIds.indexOf(item._id) > -1" class="unread" @click="enterRead(item._id)"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import {ModalEventsNameEnum} from '@/socket/socketEvents'
import {mapState, mapGetters, mapActions} from 'vuex'
import studentInfo from '../common/studentInfo.vue'
import {showToast} from '@/utils/loading'
import base64image from '../base64image.vue'
import RemarkImage from '../teacher/remark/remarkImage.vue'
import AudioPlayer from '../common/audioPlayer.vue'
import DashRightMediaItem from '../teacher/dash-answer/dash-right-media-item.vue'
export default {
  components: {studentInfo, base64image, AudioPlayer, DashRightMediaItem, RemarkImage},
  computed: {
    ...mapState(['session']),
    ...mapGetters(['getPageId', 'getPageById', 'getPage', 'getPageNum']),
    ...mapGetters('student', ['currentFeedList']),
    ...mapState({
      unreadStudentCommentIds: (state) => state.student.unreadStudentCommentIds,
    }),
    commentList() {
      const list = this.currentFeedList.filter((item) => item.to === currentUserId)
      list.sort((a, b) => {
        return a._id - b._id
      })
      list.sort((a) => {
        if (a.page === this.getPageId) return -1
        return 1
      })
      return list.map((o) => {
        return {...o, title: o.content}
      })
    },
  },
  props: {
    hidePropsStudentModal: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      modalVisibale: false,
      webHeight: window.winHeight - 80,
      slidesVisiable: [],
      unreadIdList: [],
    }
  },
  mounted() {
    EventBus.$on(ModalEventsNameEnum.SHOW_STUDENT_MODAL, (status) => {
      //console.log(status, 333333)
      if (status) {
        this.showStudentModal()
      } else {
        this.hideStudentModal()
      }
    })

    EventBus.$on(ModalEventsNameEnum.SHOW_STUDENT_MODAL_REFRESH, () => {
      showToast('new messages loaded')
      this.refreshList()
    })
  },
  methods: {
    ...mapActions('student', ['delUnreadCommentId']),
    showStudentModal() {
      this.modalVisibale = true
    },
    hideStudentModal() {
      this.modalVisibale = false
      // this.commentList = [];
    },
    getIndex(page_id) {
      const index = this.session.pages.findIndex((o) => o._id === page_id)
      return index + 1
    },
    getUrl(page_id) {
      const item = this.session.pages.find((o) => o._id === page_id)
      //return item ? item.url : false
      return item ? item.pic : false
    },
    getButtonVis(page_id) {
      if (this.getPageId == page_id) return false
      return this.getUrl(page_id)
    },
    setVis(index) {
      this.slidesVisiable[index] = !this.slidesVisiable[index]
      this.$forceUpdate()
    },
    hidecomment() {
      this.hidePropsStudentModal()
    },
    async enterRead(id) {
      if (id) {
        await App.service('comments').patch(id, {read: true})
        this.delUnreadCommentId(id)
      }
    },
    isImage(o) {
      const title = o.otext || ''
      if (typeof title === 'object') return false
      return /(\.jpg|\.png|\.jpeg|\.gif)/.test(title) || this.getIndexOf(title, 'data:image/') > -1
    },
    getIndexOf(titleValue, indexKey) {
      const typeName = Object.prototype.toString.call(titleValue)
      if (typeName === '[object String]') {
        return titleValue.indexOf(indexKey)
      } else if (typeName === '[object Object]') {
        return 'file'
      }
    },
    checkType(title) {
      return (typeof title).toLocaleUpperCase()
    },
  },
}
</script>
<style scoped>
.studentComment {
  box-sizing: border-box;
  padding: 29px 32px 29px 29px;
  background-color: #fff;
  line-height: 2rem;
  text-align: left;
  z-index: 2000;
  position: fixed;
  top: 0;
  right: 0;
  width: 550px;
  height: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.studentCommentList {
  overflow-y: hidden;
  width: 100%;
}
.studentCommentList:hover {
  overflow-y: auto;
}
.title {
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: space-between;
  line-height: 30px;
}
.title p {
  font-size: 16px;
  font-weight: bold;
}
.el-button--text:hover {
  color: #15c39a;
}
.el-button--text {
  color: #909399;
  /*
  width: 30px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  background-image: url(../../assets/picture/closecom.png);
  background-repeat: no-repeat;
  background-size: 30px 30px;
  */
}
.section {
  margin-bottom: 10px;
}
.itemtile {
  margin: 20px 0;
  height: 19px;
  font-size: 14px;
  line-height: 24px;
  color: #11142d;
  opacity: 1;
}
.feedinner {
  width: 470px;
  border: 1px solid #e6e6e6;
  background: #f7f7f7;
  opacity: 1;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
}
.readed {
  background: #ffffff;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}
.readed.unreadborder {
  border-color: red;
  /* border-width: 6px; */
}
.unread {
  border-radius: 6px;
  border-left: 2px solid red;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 470px;
  cursor: pointer;
}
.border-line {
  width: 489px;
  height: 1px;
  background-color: #dbdbdb;
}
.unreadborder .border-line {
  width: 475px;
  margin-left: 6px;
}
.pptitemouter {
  width: 80%;
  overflow: hidden;
}
.right-answer {
  padding-right: 40px;
  margin: 1rem;
}
.rightbutton {
  z-index: 1002;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 5px;
  right: 5px;
  background-repeat: no-repeat;
  background-size: 50px 50px;
  cursor: pointer;
}
.rightbutton.slideback {
  background-image: url(../../assets/picture/slideback.png);
}
.rightbutton.qa {
  background-image: url(../../assets/picture/qa.png);
}
.rightbutton:hover {
  opacity: 0.8;
}
.pptimage {
  width: 100%;
  height: 258px;
  /*background: #ffffff;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  */
  opacity: 1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.stpage {
  min-width: 60px;
  height: 20px;
  background: #e4e4e4;
  opacity: 0.4;
  text-align: center;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 16px;
  float: right;
}

.rightcomment {
  width: 100%;
  min-height: 70px;
  box-sizing: border-box;
  padding: 1rem;
  word-wrap: break-word;
  line-height: 20px;
  /* margin-top: 42px; */
  background-color: #fff;
}
.rightcommenttextdetail {
  background: #f7f7f7;
  border: 1px solid #dbdbdb;
  opacity: 1;
  border-radius: 4px;
  margin-top: 15px;
  padding: 10px;
  box-sizing: border-box;
}
.rightcommentmediadetail {
  width: 443px;
  min-height: 99px;
  background: #f7f7f7;
  border: 1px solid #dbdbdb;
  opacity: 1;
  border-radius: 4px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}
video {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}
</style>
