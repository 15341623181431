<template>
  <div class="page">
    <img src="../../assets/class_logo.png" />

    <div>

      <div class="climate-message__text--primary">You are currently blocked</div>
      <div class="climate-message__text--secondary">Please wait the teacher to unblock you</div>

      <div class="opt_area"></div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style scoped>
.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #344141;
}
.climate-message__text--secondary {
  color: #b3b3b3;
  font-size: 1.3em;
}
.climate-message__text--primary {
  color: #ffffff;
  font-size: 2.15em;
}
img {
  width: 500px;
  height: 131px;
  margin-bottom: 20px;
}

.opt_area {
  display: flex;
  margin-top: 15px;
  justify-content: center;
}

</style>
