<template>
  <div class="flex_c h100 w100">
    <div class="debug">
      <pre>{{ one }}</pre>
    </div>
    <img ref="imgRef" @load="onPicLoaded($event)" :src="`${Fn.hashToUrl(getPage.pic || getPage.url)}?p=${currentPageIndex + 1}`" alt="" />
    <iframe v-if="first || inited" ref="drawRef" class="draw-app" src="/draw/" :style="drawStyle" style="margin: 0; border: 0"></iframe>
    <!--
    <iframe v-if="first || inited" ref="drawRef" class="draw-app" src="http://127.0.0.1:3000/draw/" :style="drawStyle" style="margin: 0; border: 0"></iframe>
    -->
  </div>
</template>
<style scoped>
.debug {
  display: none;
  position: absolute;
  top: 10rem;
  left: 4rem;
  background: white;
  opacity: 0.6;
}
img {
  max-width: 100%;
  max-height: 100%;
}
.draw-app {
  position: absolute;
}
</style>
<script>
import {mapGetters} from 'vuex'
import {showLoading, hideLoading} from '../../utils/loading'
export default {
  computed: {
    ...mapGetters(['getPage', 'getPageId', 'getQuestion']),
    ...mapGetters('student', ['myPageAnswerList', 'currentPageIndex']),
    drawStyle() {
      return `width:${this.imgPos.width}px;height:${this.imgPos.height}px;top:${this.imgPos.y}px;left:${this.imgPos.x}px;`
    },
  },
  created() {},
  watch: {
    getPageId(o) {
      this.uninit()
    },
  },
  mounted() {
    console.log('<=================mounted====>')
    showLoading()
    window.addEventListener('message', this.onMessage)
    window.addEventListener('resize', this.getDimensions)
    this.interval = setInterval(this.process, 100)
  },
  props: {
    sendCanvas: {type: Function},
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      first: true,
      processi: 0,
      uuid: '',
      base64Str: '',
      inited: false,
      drawOld: '',
      drawNew: '',
      locked: false,
      isDrawOk: false,
      imgPos: {width: 0, height: 0, x: 0, y: 0},
      one: {},
      drawRatio: 0,
      interval: null,
    }
  },
  destroyed() {
    console.log('<===================destoryed====>')
    this.doDestroy()
  },
  beforeDestroy() {
    console.log('<===================before destory====>')
    this.doDestroy()
  },
  methods: {
    async init() {
      showLoading()
      this.drawOld = ''
      this.uuid = this.$route.params.id + this.getPageId
      this.base64Str = ''
      this.processi = 0
      this.one = {}
      this.getImgRatio()
      await this.getDrawData()
      this.initScene()
    },
    onMessage({data}, a) {
      if (data?.app !== 'draw') {
        return
      }

      if (data.ok) {
        console.log('<====The drawing board is initialized=====')
        this.isDrawOk = true
        hideLoading()
      }

      console.log('<====onMessage====>')
      if (data?.body?.appState.pageId !== this.uuid) {
        console.warn(data?.body?.appState.pageId, this.uuid, '<=====page id mismatch====>')
        this.updateCanvas()
        hideLoading()
      }

      if (data.body && data.body.appState.viewBackgroundColor !== 'transparent') {
        console.warn('<=====reset canvas====>')
        hideLoading()
        this.locked = true
        this.clearCanvas(this.uuid)
      }

      if (this.inited && data?.body?.appState?.pageId == this.uuid) {
        hideLoading()
        this.processi = 0
        this.drawNew = {
          type: 'excalidraw',
          appState: {
            zoom: data.body.appState.zoom ?? {value: 1},
            viewBackgroundColor: 'transparent',
            pageId: data.body.appState.pageId,
          },
          elements: data.body.elements,
          files: data.body.files,
        }
        this.base64Str = data.src
        if (data.body.elements.length > 0) {
          this.locked = false
        }

        console.log(structuredClone(this.drawNew), '<====new drawing data arrived====>')
      }
    },
    async postMessage(data) {
      if (!this.$refs?.drawRef?.contentWindow) {
        await sleep(100)
        return this.postMessage(data)
      }
      return this.$refs.drawRef.contentWindow.postMessage(data, '*')
    },
    clearCanvas(id) {
      this.postMessage({
        app: 'draw',
        get: 'info',
        elements: [],
        appState: {viewBackgroundColor: 'transparent', zoom: {value: this.drawRatio}, pageId: id},
      })
    },
    onPicLoaded(e) {
      console.log('<====slide image loaded====>')
      this.init()
    },
    getImgRatio() {
      var image = new Image()
      image.src = Fn.hashToUrl(this.getPage.pic || this.getPage.url)
      image.onload = () => {
        const rs = this.$refs.imgRef?.width / image.width
        if (this.isDrawOk && this.drawRatio !== rs) {
          this.postMessage({app: 'draw', appState: {zoom: {value: rs}}})
        }
        this.drawRatio = rs
        console.log(rs, '<====getImgRatio image loaded====')
      }
    },
    getDimensions() {
      if (this.$refs.imgRef) {
        this.imgPos.x = this.$refs.imgRef?.offsetLeft
        this.imgPos.y = this.$refs.imgRef?.offsetTop
        this.imgPos.width = this.$refs.imgRef?.width
        this.imgPos.height = this.$refs.imgRef?.height
        this.getImgRatio()
      }
    },
    updateCanvas() {
      if (this.one._id && this.drawOld) {
        this.postMessage({app: 'draw', get: 'info', elements: this.drawOld.elements, appState: {...this.drawOld.appState, zoom: {value: this.drawRatio}}})
      } else {
        this.clearCanvas(this.uuid)
      }
    },
    async initScene() {
      console.log(`drawOK: ${this.isDrawOk}, drawRatio: ${this.drawRatio}, <====initScene====`)
      if (this.isDrawOk && this.drawRatio) {
        console.log(this.one, '<====initScene====')
      } else if (this.process) {
        await sleep(50)
        return this.initScene()
      }

      this.updateCanvas()
      this.getDimensions()
      this.uuid = this.$route.params.id + this.getPageId
      this.inited = true
      this.first = false
    },
    async getDrawData() {
      if (this.myPageAnswerList[0]) {
        this.one = this.myPageAnswerList[0]
      }
      if (this.one._id) {
        const rs = await App.service('response').get(this.one._id)
        if (rs?.json) {
          this.drawOld = rs.json
          if (!this.drawOld.appState.pageId || this.drawOld.appState.pageId.indexOf(this.$route.params.id) == -1) {
            this.drawOld.appState.pageId = this.uuid
          }
          console.log(this.drawOld, '<====getDrawData====')
        }
      } else {
        console.warn('<====never pained, frist visit====>')
      }
    },
    async process() {
      let needPatch = false
      if (this.processi > 6 && this.inited && this.drawNew?.appState?.pageId == this.uuid) {
        if (this.drawNew && JSON.stringify(this.drawOld) !== JSON.stringify(this.drawNew)) {
          if (!(!this.drawNew.elements.length && !this.one._id)) {
            //
            needPatch = true
          } else {
            console.log('<====waiting for response...====')
          }
        }
        this.processi = 0

        console.log(needPatch, '<====need patch or not====')
      }
      if (needPatch) {
        this.drawOld = {...this.drawNew}

        await this.sendCanvas({...this.one, ...{locked: this.locked}}, this.base64Str, this.drawNew)
        if (this.myPageAnswerList[0]) {
          this.one = this.myPageAnswerList[0]
        }
        //this.getDrawData()

        console.log(needPatch, '<====patched====')
      } else {
        this.processi++
      }
    },
    uninit() {
      showLoading()
      this.clearCanvas('notexist')
      this.inited = false
      this.one = {}
      this.drawOld = ''
      this.drawNew = ''
      this.uuid = ''
    },
    doDestroy() {
      hideLoading()
      this.process = null
      window.removeEventListener('message', this.onMessage)
      window.removeEventListener('resize', this.getDimensions)
      if (this.interval) clearInterval(this.interval)
    },
  },
}
</script>
