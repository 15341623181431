<template>
  <el-dropdown class="flex_l" @command="handleCommand">
    <span class="el-dropdown-link flex_c">
      <student-avatar :student="user"></student-avatar>
      <span style="min-width: 4rem;">{{user.nickname || user.email}}</span>
      <i class="el-icon-arrow-down el-icon--right"></i>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="leave">Leave session</el-dropdown-item>
      <el-dropdown-item command="logout">Sign out</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
import { mapState } from 'vuex'
import PPT from "@/utils/pptConfig";
import StudentAvatar from '../common/studentAvatar';
export default {
  components: { StudentAvatar },
  computed: {
    ...mapState(['class_id']),
    ...mapState('student', ['user'])
  },
  mounted() {
  },
  methods: {
    async handleCommand(cmd) {
      if (cmd === 'leave') {
        if (this.$store.state.user?.email?.includes('@')) location.href = PPT.formWebUrl
        else location.href = `https://${PPT.isDev ? 'dev' : 'my'}.classcipe.com`
      } else {
        console.log('logout')
        await App.authentication.removeAccessToken()
        location.href= `https://${PPT.isDev ? 'dev' : 'my'}.classcipe.com`
      }
    }
  }
}
</script>
<style scoped>
.el-dropdown{
  cursor: pointer;
  text-align: left;
  display: flex;
  margin-right: 0.5rem;
  padding: 0.3rem 0.5rem 0.3rem 0.3rem;
  background-color: #f4f4f4;
  border-radius: 1.5rem;
}
</style>
