<template>
<div class="edit-area">
  <div class="textarea-box">
    <keyboard ref="textareaRecord" rows=4 focus="true" type="textarea" class="textarea" v-model="commentValue" placeholder="" spellcheck="false" @blur="blurFn" @change="changeInput"></keyboard>
    <!--
    <textarea ref="textareaRecord" class="textarea" v-model="commentValue" placeholder="" :autofocus="true" spellcheck="false" @blur="blurFn" @change="changeInput"></textarea>
    -->
  </div>
</div>
</template>
<script>
import keyboard from '@/components/common/keyboard.vue'
export default {
  components: {
    keyboard
  },
  props: {
    onSend: { type: Function, default: () => null },
    cancel: { type: Function, default: () => null },
    item: { type: Object, default: () => { return {}}},
    defaultText: { type: String, default: '' }
  },
  computed: {
    showPlaceholder() {
      return !this.commentValue
    }
  },
  data() {
    return {
      commentValue: '',
      sendDelay: null,
    }
  },
  created() {
    this.commentValue = this.defaultText
  },
  mounted() {
    this.focusInput()
  },
  methods: {
    focusInput() {
      this.$refs.textareaRecord?.focusInput()
      window.preventSendRemark = false
    },
    async blurFn() {
      console.log('blurFn<===========')
      await sleep(200)
      if (window.preventSendRemark) return this.focusInput()
      if (this.item._id) await this.onSend(this.item, this.commentValue, 'text')
      else await this.onSend(this.commentValue, 'text')
      this.cancel()
    },
    async changeInput() {
      console.log('changeInput<===========')
      window.preventSendRemark = false
      //if (!this.commentValue) return
      //if (this.item._id) await this.onSend(this.item, this.commentValue, 'text')
      //else await this.onSend(this.commentValue, 'text')
      //this.cancel()
    }
  }
}
</script>
<style scoped>
.shadow{
  box-shadow: 0px 1px 3px 3px rgb(0 0 0 / 16%);
  border-radius: 8px;
}
.textarea-box{
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  /*
  height: 100px;
  border: 1px solid #D8D8D8;
  */
  opacity: 1;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  outline:none;
}
.textarea {
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 5px;
  border: none;
  box-sizing: border-box;
  outline: none;
  resize: none
}
.edit-area{
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
.text-placeholder{
  position: absolute;
  top: 50%;
  height: 22px;
  transform: translateY(-11px);
  left: 20px;
  font-size: 14px;
  line-height: 24px;
  color: #828282;
  padding-right: 30px;
  text-align: right;
  background-image: url(../../assets/picture/pencial.png);
  background-size: 22px 22px;
  background-position: bottom 0 right 0;
  background-repeat: no-repeat;
  pointer-events: none;
}
.footer-button{
  margin-top: 19px;
  height: 40px;
  display: flex;
  justify-content: center;
}
.send-button{
  width: 80px;
  height: 30px;
  border-radius: 25px;
  box-sizing: border-box;
  background-color: rgba(208, 210, 223, 1);
  line-height: 30px;
  font-size: 12px;
  font-weight: bolder;
  color: rgba(255, 255, 255, 1);
  background-image: url(../../assets/picture/new-send.png);
  background-size: 14px 14px;
  background-position: bottom 8px right 16px;
  background-repeat: no-repeat;
  cursor: pointer;
  text-align: left;
  padding-left: 15px;
}
.send-button.active{
  background-color: #15C39A;
}
</style>
