<template>
  <el-button v-if="session.zoom?.id" round size="small" class="theme flex_c md_r05" @click="toZoom">
    <div class="flex_c">
      <img src="../assets/meetings.png" class="flex_c" style="height:1.2rem;">
      <img src="../assets/ZoomLogo.png" class="flex_c" style="height:0.8rem;padding-left:0.2rem;">
    </div>
  </el-button>
</template>
<script>
import {mapState} from 'vuex'
export default {
  computed: {
    ...mapState(['session'])
  },
  methods: {
    toZoom() {
      const { join_url } = this.session.zoom ?? {}
      if(!join_url) return
      window.open(join_url, 'zoom', 'height=600, width=800, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no')
    },
  }
}
</script>