<template>

  <div class="remark-container">
    <div v-show="accessTask" class="flex_c h100 w100" style="position: fixed; z-index: 2047; top: 0; left: 0; background: #000">
      <div style="z-index: 100; position: absolute; right: 0.5rem; top: 0.5rem">
        <el-button icon="el-icon-close" circle @click="accessTask = false"></el-button>
      </div>
      <iframe v-if="accessTask" ref="taskRef" id="accessTask" :src="taskUrl" style="width:100%;height:100%;margin: 0; border: 0"></iframe>
    </div>
    <div class="remark-control">
      <el-tooltip content="Record an Aduio" placement="top">
        <div class="remark-button-outer">
          <img @click="audio" src="../../assets/picture/voice-button.png" class="remark-button" />
        </div>
      </el-tooltip>
      <el-tooltip content="Record a Video" placement="top">
        <div class="remark-button-outer">
          <img @click="video" src="../../assets/picture/video.png" class="remark-button" />
        </div>
      </el-tooltip>
      <el-tooltip content="Upload Response" placement="top">
        <div class="remark-button-outer" @click="upFile">
          <img src="../../assets/picture/upload.png" class="remark-button" />
          <!-- <common-upload :onSuccess="pushToUploadPool" :onlyGetFile="true"/> -->
        </div>
      </el-tooltip>
      <el-tooltip content="Access Task" placement="top" v-if="session.type == 'workshop'">
        <div class="remark-button-outer" @click="accessTask = true">
          <img src="../../assets/picture/add.png" class="remark-button" />
        </div>
      </el-tooltip>
    </div>
    <ul class="remark-list">
      <!--输入区域item-->
      <li v-if="recordType" class="remark-list-item record-item active-item">
        <div class="item-header">
          <div @click.stop="cancelRecord" class="delete-button"></div>
        </div>
        <div class="remark-item-content remark-item-content-record">
          <record-video
            v-if="recordType === ModalEventsTypeEnum.VIDEO"
            :onSend="sendCommentCb"
            :cancel="cancelRecord"
            :isAniInFixed="false"
            ref="recordVideo"/>
          <record-audio
            v-else-if="recordType === ModalEventsTypeEnum.AUDIO"
            :onSend="sendCommentCb"
            :cancel="cancelRecord"
            :onRecordDone="focusIndex"
            :isAniInFixed="false"
            ref="recordAudio"/>
        </div>
      </li>
      <li class="remark-list-item" v-for="item in uploadPool" :key="item._id">
        <uploading-progress :onSuccess="onProgressDone" :item="item">
          <div class="item-header">
            <div @click.stop="cancelUpLoad(item._id)" class="delete-button"></div>
          </div>
        </uploading-progress>
      </li>
      <li :class="`remark-list-item ${(recordType || uploadPool.length > 0) ? 'remark-list-item-gray' : ''}`" 
        v-for="(item, index) in myPageAnswerList" :key="item._id"
        :tabindex="index === 0 ? '0' : ''" v-loading="loading[item._id]"
        :ref="index === 0 ? 'activeRef': ''">
        <div class="item-header">
          <el-popconfirm v-if="item._id" @confirm="deleteItem(item._id)" confirm-button-text="OK" cancel-button-text="No,Thanks" icon="el-icon-info" title="Are you sure you want to delete this?">
            <div slot="reference" class="delete-button"></div>
          </el-popconfirm>
          <div class="flex1 flex_c" v-if="Fn.fileType(item.content) === 'task'">
            <p class="task-file-name">{{item.content.split(':').pop()}}</p>
          </div>
        </div>
        <div class="remark-item-content">
          <VideoPlayer v-if="Fn.fileType(item.content) === 'video'"
            controlslist="nodownload" :controls="true" :src="item.content"
            width="280" height="150" preload="auto"/>
          <audio-player v-else-if="Fn.fileType(item.content) === 'audio'"
            :url="item.content"/>
          <div class="item-content-pic" v-else-if="Fn.fileType(item.content) === 'image'">
            <base64image :url="item.content" :noSync="true" :showPreview="true"/>
          </div>
          <div class="item-content-pic" v-else-if="Fn.fileType(item.content) === 'task'"  @click="fullView(item.content)">
            <base64image :url="item.content"/>
          </div>
          <div class="remark-file" v-else @click="fullView(item.content)">
            <div class="file-icon" :class="Fn.fileIcon(item.content)"></div>
            <div class="flex1">
              <p class="file-name">{{item.content.split(':').pop()}}</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { ModalEventsTypeEnum } from "@/socket/socketEvents";
import { mapActions, mapState, mapGetters } from "vuex";
import RecordAudio from "../common/recordAudio.vue";
import RecordVideo from "../common/recordVideo.vue";
import { getAnswerTimeStr } from "@/utils/help";
import AudioPlayer from "../common/audioPlayer.vue";
import base64image from '../base64image.vue';
import CommonUpload from '../common/commonUpload.vue';
import UploadingProgress from '../common/uploadingProgress.vue';
export default {
  components: {
    RecordVideo,
    RecordAudio,
    AudioPlayer,
    base64image,
    CommonUpload,
    UploadingProgress
  },
  computed: {
    ...mapState({
      currentPageIndex: state => state.student.currentPageIndex,
      isViewGroup: state => state.student.isViewGroup
    }),
    ...mapState(['session']),
    ...mapGetters(['getQuestion']),
    ...mapGetters('student', ['myPageAnswerList'])
  },
  data() {
    return {
      loading: {},
      taskUrl: null,
      accessTask: false,
      ModalEventsTypeEnum,
      recordType: null,
      uploadPool: [], // {id: date.now, file}
      nextType: null
    };
  },
  props: {
    addMediaAnswer: { type: Function },
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessage)
  },
  created() {
    const isDev = /(dev|localhost|127\.0\.0\.1|192\.168\.|10\.0\.)/.test(location.hostname)
    this.taskUrl = isDev ? 'https://dev.classcipe.com/v2/com/classroom/import/3' : 'https://classcipe.com/v2/com/classroom/import/3'
    window.addEventListener('message', this.onMessage)
  },
  methods: {
    ...mapActions("student", ["updateAnswerdPage"]),
    audio() {
      this.checkRecording(ModalEventsTypeEnum.AUDIO)
    },
    video() {
      this.checkRecording(ModalEventsTypeEnum.VIDEO)
    },
    fullView(url) {
      this.$store.commit('upFullDoc', { url })
    },
    async onMessage({data}, a) {
      if (data?.app == 'web') {
        this.accessTask = false
        const rs = await this.sendCommentCb([data.image, 'task', data.id, data.name].join(':'))
      }
    },
    async upFile() {
      //const data = await Fn.fileUpLoadUiX('.doc, .docx, .pdf, application/pdf,audio/*,video/*,image/*')
      const {page, type} = this.getQuestion
      const data = await Fn.fileUpLoadUiX('.pdf, application/pdf,audio/*,.mp4,.webm,image/*')
      if (data.message) {
        this.$message.error(data.message);
      }
      if (!data) return
      const uid = Object.keys(data.title)[0]
      const rs = await this.sendCommentCb([data._id, data.ext, data.title[uid]].join(':'), page, type)
      console.log(data, rs, 'upfile')
    },
    checkRecording(nextType) {
      if(this.recordType === nextType) return null
      if(this.recordType === ModalEventsTypeEnum.AUDIO) {
        this.nextType = nextType
        this.$refs.recordAudio.done()
      } else if(this.recordType === ModalEventsTypeEnum.VIDEO) {
        this.nextType = nextType
        this.$refs.recordVideo.doneRecord()
      } else {
        this.focusIndex()
        this.recordType = nextType;
      }
    },
    async deleteItem(id) {
      console.log('del media', id)
      this.loading[id] = true
      this.$forceUpdate()
      await this.$store.dispatch('student/delResponse', id)
      delete this.loading[id]
    },
    getTimeStr(time) {
      return getAnswerTimeStr(time * 1000);
    },
    cancelRecord() {
      if(this.nextType) {
        this.recordType = this.nextType;
      } else {
        this.recordType = null;
      }
      this.nextType = null
    },
    async onProgressDone(item){
      await this.cancelUpLoad(item._id)
    },
    async sendCommentCb(link, page, type) {
      this.cancelRecord();
      const rs = await this.addMediaAnswer(link, page, type)
      // 已答
      this.updateAnswerdPage(this.currentPageIndex);
      this.focusIndex()
      return rs
    },
    getIconClass(name) {
      if (!name) return "file"
      const ext = name.split(':')[1]
      if (ext === "pdf") return "pdf"
      return Fn.fileType(name)
    },
    focusIndex() {
      this.$nextTick(() => {
        this.$refs.activeRef?.[0]?.focus();
      });
    },
    pushToUploadPool(file) {
      console.log(file, 123)
      this.uploadPool.push({
        file,
        id: Date.now()
      })
    },
    cancelUpLoad(id) {
      const index = this.uploadPool.findIndex(item => item._id == id)
      this.uploadPool.splice(index, 1)
    }
  }
};
</script>
<style scoped>
.item-content-pic {
  width: 280px; 
  height: 150px; 
  position: relative;
}
.remark-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.remark-control {
  width: 310px;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 6px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 29px;
  margin-top: 20px;
  margin-left: 20px;
}
.control-left {
  display: flex;
}
.remark-button-outer {
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}
.remark-button-outer.active {
  background: rgba(21, 195, 154, 0.1);
  border: 1px solid #15c39a;
  opacity: 1;
  border-radius: 4px;
}
.remark-button {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.remark-add-button {
  width: 31px;
  height: 31px;
  cursor: pointer;
}
.delete-button {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background-image: url(../../assets/picture/delete.png);
  background-size: 16px 16px;
  background-position: 9px 9px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.delete-button:hover {
  background-color: tomato;
}
.remark-list {
  padding: 20px;
  width: 350px;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}
.remark-list-item {
  width: 310px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 6px;
  margin-bottom: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 130px;
}
.remark-list-item.record-item {
  height: auto;
}
.remark-list-item.text-item {
  height: 180px;
}
.remark-list-item.remark-list-item-gray{
  opacity: 0.4;
}
.item-header {
  width: 310px;
  height: 40px;
  background: #15c39a;
  opacity: 1;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 5px;
}
.user-icon {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  margin-right: 11px;
  background-color: #fff;
  line-height: 34px;
  text-align: center;
  font-size: 20px;
  color: #333;
}
.user-name {
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  opacity: 1;
  text-align: left;
}
.user-time {
  font-size: 10px;
  line-height: 14px;
}
.remark-item-content {
  width: 310px;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 15px 15px 0;
  margin-bottom: 15px;
  flex: 1;
  word-break: break-all;
}
.remark-item-content.remark-item-content-record{
  margin-bottom: 0;
}
.remark-file {
  min-height: 60px;
  display: flex;
  align-items: center;
}
video {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}
.download-text {
  text-decoration: none;
  text-align: left;
  float: left;
  padding-right: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  background-image: url(../../assets/picture/download.png);
  background-size: 12px 11px;
  cursor: pointer;
  line-height: 24px;
  color: #15c39a;
}
.file-name {
  font-size: 12px;
  line-height: 24px;
  color: #000000;
  text-align: left;
}
.task-file-name {
  font-size: 14px;
  /*line-height: 24px;*/
  color: #fff;
  width: 250px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
