<template>
  <div class="flex1 flex_v h100 w100">
    <iframe class="flex1 h100 w100 br_0" :src="`${getQuestion.data}`" />
    <div @click="openUrl" class="flex_ac note--div h_3 pd_l1">
      <div style="min-width:70px;">
        Viewing
      </div>
      <div class="url--text pd_l05">{{ getQuestion.data }}</div>
    </div>
  </div>
</template>
<style scoped>
.note--div {
  color: white;
  background-color: #7a9494;
}
.url--text {
  color: #c9de55;
  cursor: pointer;
  text-overflow: ellipsis;
  line-height: 50px;
  overflow: hidden;
  white-space: nowrap;
}
.url--text:hover {
  color: #29abe2;
}
</style>
<script>
import {mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters(['getQuestion']),
  },
  methods: {
    openUrl() {
      window.open(this.getQuestion.data, '_blank')
    },
  },
}
</script>
