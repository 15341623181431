<template>
  <div class="page student_page" v-if="isOk">
    <el-dialog title="" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" :visible.sync="canceled" :append-to-body="true">
      <div class="dialog_page">
        <div>This session has been cancelled, please register for other workshops</div>
        <div class="flex_r pd_t1">
          <el-button type="danger" @click="backToClass()">Confirm</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="!(roomGroup || {})._id ? 'Join in group' : 'Group member'"
      :visible.sync="showGroupDialog"
      :close-on-click-modal="false"
      :show-close="true"
      custom-class="custom-dialog">
      <joinGroup />
    </el-dialog>
    <ClassRoomClosed v-if="session && session.status == 'close'" />
    <!--
    <pageLockedNote v-else-if="session && (lock_all_pages || isPageLocked()) && getQuestion">
    -->
    <pageLockedNote v-else-if="!isBlock && session && (lock_all_pages || isPageLocked())">
      <!--
      <div class="sfooter" v-if="getPageNum > 0 && lock_all_pages">
        <studentControlPanel
          :showStudentModal="showStudentModal"
          :isShowQuestion="isShowQuestion"
          :changeShowOrAnswer="changeShowOrAnswer"
          :fullScreenWidth="screenWidth"
          :screenWidth="currentScreenWidth"
          :smallWindow="smallWindow"
          :toggleResponse="toggleResponse"
          :isResponseShow="isResponseShow"
          :isPageAllLock="true" />
      </div>
      -->
    </pageLockedNote>

    <div class="student-page" v-else>
      <students-ppt-list v-if="session.status === 'student'" :changePage="pageChange" />
      <div class="student-main" v-show="!questionModalVisiable">
        <template v-if="!isResponseShow">
          <div v-if="fullScreen && (!getQuestion || getQuestion.type !== 'draw')" class="full_screen" @click="showFullScreen(false)">
            <div style="position: absolute; top: 0; left: 0; z-index: 8889"><div class="dash-header-left" @click="showFullScreen(false)"></div></div>
            <PptDetail :showLogo="true" />
          </div>
          <div class="student-left" v-if="getQuestion?.type !== 'draw' || isMaterialShow">
            <div class="st-ppt-outer">
              <PptDetail class="ppt-out-line" :showLogo="true" />
            </div>
          </div>
          <div class="student-right" :style="`width: ${getWidthPercent()}`" v-if="getQuestion?.type && !(isMaterialShow && getQuestion.type === 'draw')">
            <!--
          <div class="student-right" :class="!isShowQuestion ? 'show-question' : ''" :style="`width: ${getWidthPercent()}`" v-if="getQuestion?.type && !(isMaterialShow && getQuestion.type === 'draw')">
          -->
            <StudentsIndex :answerCall="answerCall" />
          </div>
        </template>
        <div class="right-fix-area">
          <tips-list v-if="overviewModalVisiable" :filterTips="filterTips" />
          <StudentComment :hidePropsStudentModal="hideStudentModal" />
        </div>

        <answer-and-students v-if="getQuestion && getQuestion.type !== 'website' && isResponseShow" />

        <div class="sfooter" v-if="getPageNum > 0">
          <studentControlPanel
            :showStudentModal="showStudentModal"
            :isShowQuestion="isShowQuestion"
            :changeShowOrAnswer="changeShowOrAnswer"
            :fullScreenWidth="screenWidth"
            :screenWidth="currentScreenWidth"
            :smallWindow="smallWindow"
            :toggleResponse="toggleResponse"
            :isResponseShow="isResponseShow"
            :isPageAllLock="lock_all_pages" />
        </div>
      </div>
    </div>

    <!--
    <pre style="color:red;padding-top:30vh;z-index:100000;">
      lock_all_pages:{{lock_all_pages}}
      isPageLocked: {{isPageLocked()}}
      countdown: {{session.countdown}}
    </pre>
        <div class="deadline_info" v-if="session.countdown?.type === 2 && countDown.status === 1">
      <pre>countDown.status:{{countDown.status}}</pre>
      <pre>session.status:{{session.status}}</pre>
      <pre>session.sessionType :{{session.sessionType}}</pre>
      <div v-if="session.status !== 'close' && countDown.status">
    -->
    <div class="top_btn">
      <div v-if="session.status !== 'close'">
        <div class="deadline_info" v-if="session.countdown?.type === 2">
          <span v-if="countDown.status === 1">
            {{ countDownTimeText ? 'Countdown : ' + countDownTimeText : 'loading...' }}
          </span>
          <span v-else>
            {{ 'Countdown : ' + session.countdown.down + ' mins' }}
          </span>
        </div>
        <div class="deadline_info" v-if="session.countdown?.type === 1">Deadline : {{ countDownMinText }}</div>
      </div>
      <!--
      <el-tooltip content="mark up and send comment" placement="top">
        <div class="readchat comment"></div>
      </el-tooltip>
      -->

      <div class="deadline_info" v-if="showCorrect">You are unable to change your answer</div>

      <img
        src="../assets/icon/screen_all.png"
        width="50"
        height="50"
        class="icon"
        @click="showFullScreen(true)"
        v-if="!fullScreen && !smallWindow && !questionModalVisiable && getQuestion && getQuestion.type !== 'draw' && session.status !== 'close'" />
      <div v-if="rooms.groups.length > 0 && session.status !== 'close'" class="invite-button" @click="showGroupModal">
        <span v-if="(roomGroup || {})._id">Group member</span>
        <span v-else>Join Group</span>
        <!--
        <span v-else-if="!isNotAvailable()">Join Group</span>
        -->
      </div>
      <div class="invite-button" @click="eSelf" v-show="showStudentEvaluation">
        <span>Self-review</span>
      </div>
      <div class="invite-button" @click="eOthers" v-show="showPeerEvaluation">
        <span>Peer-review</span>
      </div>
      <div class="flex1 flex_r">
        <zoomBtn />
        <student-account></student-account>
      </div>
    </div>
    <div id="diycolor_comment"></div>

    <el-dialog
      :visible.sync="wrongClassCodeVisible"
      custom-class="custom-dialog"
      width="80%"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false">
      <wrong-code />
    </el-dialog>

    <joinZoom v-if="!isNotAvailable() && user._id && session.zoom?.id && !lock_all_pages && !(!countdownStart && session.countdown.type == 2)" />

    <el-dialog
      :visible.sync="startCountdownVisible && session.status !== 'close' && !isBlock"
      class="flex_c"
      width="600px"
      title="Reminder"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false">
      <span>Start countdown right now?</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="" @click="cancelLocalCoutdown">Cancel</el-button>
        <el-button type="primary" @click="startLocalCoutdown">Confirm</el-button>
      </span>
    </el-dialog>

    <big-preview />

    <comment-modal />

    <el-dialog title="Class is still in progress, are you sure you want to leave?" :visible.sync="showBackConfirm" :append-to-body="true">
      <span slot="footer" class="dialog-footer">
        <el-button @click="showBackConfirm = false">No</el-button>
        <el-button type="primary" @click="backToClass">Yes</el-button>
      </span>
    </el-dialog>

    <preview-metarial-item v-if="isMaterialShow && isStudentPaced" />

    <fullDoc />
    <fullPlay />
    <StudentLoginPage v-if="isAnonymous && !isNotAvailable()" :guestJoinRoom="guestJoinRoom" :googleLogin="googleLogin" />
    <el-dialog
      v-loading="isWaitingStart && !displayTime"
      :center="true"
      :fullscreen="true"
      :visible.sync="isWaitingStart"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false">
      <WaitingStart v-if="displayTime" :displayTime="displayTime" />
    </el-dialog>
    <el-dialog
      :visible.sync="isBlock"
      custom-class="custom-dialog"
      width="80%"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false">
      <block-modal />
    </el-dialog>
  </div>
</template>

<script>
import {showLoading, hideLoading, showToast} from '../utils/loading'
import StudentsIndex from '../components/students/Index.vue'
import {ModalEventsNameEnum} from '../socket/socketEvents'
import fullPlay from '@/components/fullPlay.vue'
import StudentComment from '@/components/students/studentComment.vue'
import ClassRoomClosed from '@/components/students/classRoomClosed.vue'
import studentControlPanel from '@/components/students/studentControlPanel.vue'
import pageLockedNote from '@/components/students/pageLockedNote.vue'
import colorSelector from '@/utils/color'
import TipsList from '@/components/common/tipsList.vue'
import fullDoc from '@/components/fullDoc.vue'
import {mapActions, mapState, mapGetters, mapMutations} from 'vuex'
import StudentsPptList from '@/components/students/studentsPptList.vue'
import StudentLoginPage from '@/components/students/studentLoginPage.vue'
import WaitingStart from '@/components/students/waitingStart.vue'
import BigPreview from '@/components/common/bigPreview.vue'
import joinGroup from '@/components/students/joinGroup.vue'
import WrongCode from '@/components/common/wrongCode.vue'
import AnswerAndStudents from '@/components/students/answerAndStudents.vue'
import PptDetail from '@/components/pptDetail.vue'
import CommentModal from '@/components/teacher/commentModal.vue'
import PPT from '@/utils/pptConfig'
import BlockModal from '@/components/common/blockModal.vue'
import PreviewMetarialItem from '@/components/drag/previewMetarialItem.vue'
import studentAccount from '@/components/students/studentAccount'
import zoomBtn from '@/components/zoomBtn'
import joinZoom from '@/components/students/joinZoom'

window.currentUserId = ''

export default {
  components: {
    WaitingStart,
    StudentsIndex,
    StudentComment,
    ClassRoomClosed,
    studentControlPanel,
    pageLockedNote,
    TipsList,
    StudentsPptList,
    StudentLoginPage,
    BigPreview,
    joinGroup,
    WrongCode,
    AnswerAndStudents,
    PptDetail,
    CommentModal,
    BlockModal,
    PreviewMetarialItem,
    studentAccount,
    fullDoc,
    zoomBtn,
    joinZoom,
    fullPlay,
  },
  data() {
    return {
      isOk: false,
      canceled: false,
      pptUrl: null,
      title: '',
      options: [],
      currentSo: null,
      allAnswers: {},
      uname: '',
      type: '',
      currentData: null,
      currentAnswerd: false,
      unread: false,
      modalVisiable: false,
      oken: '',
      groupName: '',
      answerList: [],
      deadLineTimer: null,
      limitText: null,
      lock_all_pages: false,
      questionModalVisiable: false, // ppt 反馈面板
      showCorrect: false,
      fullScreen: false,
      screenWidth: 0,
      screenHeight: 0,
      isShowQuestion: true,
      currentScreenWidth: 700,
      smallWindow: false,
      smallWindowValue: 800,
      link: '',
      overviewModalVisiable: false,
      studentCommentLoaded: false,
      firstJoined: true,
      showTip: false,
      websiteUrl: '',
      showLoginDialog: false,
      metrialStatusMap: {},
      isWaitingStart: true,
      displayTime: '',
      waitingTimer: null,
      refreshTimer: null,
      showGroupDialog: false,
      showNetErrorDelay: null,
      isAnonymous: false, // 是否匿名登录url
      isNotJoin: true,
      isVisitor: false, // 是否游客
      wrongClassCodeVisible: false,
      startCountdownVisible: false,
      countdownStart: null,
      isResponseShow: false,
      isResponseShowMap: {},
      countDownTimeText: '', // countdown cd text
      countDownMinText: '', // deadline cd text
      // tempData: {
      //   resList: null,
      //   commentList: null
      // },
      // 根据评估表页面是否开启自评、他评显示对应的按钮。
      updateEvaluationStatusTimer: null,
      showStudentEvaluation: false,
      showPeerEvaluation: false,
      showBackConfirm: false,
      isJoinedByMySelf: false, // 是否是自己加入的
    }
  },
  computed: {
    ...mapGetters(['isMaterialShow', 'isStudentPaced', 'getPage', 'getPageNum', 'getQuestion']),
    ...mapGetters('student', ['myPageAnswerList', 'roomGroup']),
    ...mapState(['session', 'class_id', 'countDown']),
    ...mapState('student', ['user', 'rooms', 'groups']),
    ...mapState({
      currentPageIndex: (state) => state.student.currentPageIndex,
      allAnswerList: (state) => state.student.allAnswerList,
      allRemarks: (state) => state.remark.allRemarks,
      studentList: (state) => state.teacher.studentList,
    }),
    isBlock() {
      return this.rooms.block.includes(this.user._id)
    },
    filterTips() {
      return []
    },
  },
  async created() {
    const {commit, dispatch} = this.$store
    commit('setClassId', {id: this.$route.params.id, name: this.$route.name})
    const query = this.$route.query
    await dispatch('getClassInfo')
    this.isAnonymous = !!this.session.guest
    if (window.reSendIt) clearInterval(window.reSendIt)
    window.reSendIt = setInterval(() => {
      if (!App.authentication.authenticated) return
      App.service('auth').patch(null, {on: 'connect', from: 'login', role: 'student', _sid: this.class_id})
    }, 60000)
    App.service('session').removeAllListeners(['patched', 'removed'])
    App.service('session')
      .on('patched', (rs) => {
        if (this.session._id !== rs._id) return console.warn('session id invalid:', rs)
        const oldStatus = this.session.status
        commit('patchSession', rs)
        if (rs.status === 'student') {
          if (oldStatus !== rs.status) {
            this.setLastPage()
          }
        } else {
          this.isResponseShow = false
        }
      })
      .on('removed', (rs) => {
        if (this.session.sid === rs.sid) {
           this.canceled = true
        }
      })
    App.service('response').removeAllListeners(['created', 'patched', 'removed'])
    App.service('response')
      .on('created', (rs) => {
        commit('student/setAllAnswerdListOne', rs)
      })
      .on('patched', (rs) => {
        commit('student/setAllAnswerdListOne', rs)
      })
      .on('removed', (rs) => {
        commit('student/deleteOnAnswerById', rs._id)
      })
    const token = query.token || (await App.authentication.getAccessToken()) || ''
    if (this.session.status == 'student') this.setLastPage()
    if (!this.session.sid) {
      this.wrongClassCodeVisible = true
      return false
    }
    if (!token) return this.goToLoginAfterLoginout()
    const {authentication, user} = await App.authenticate({strategy: 'jwt', accessToken: token}, {query: {sid: this.class_id, role: 'student'}})
    if (authentication) {
      Acan.url('token', null)
      await this.initWithToken(user)
      this.isOk = true
    } else {
      this.goToLoginAfterLoginout()
    }
    await dispatch('getClassInfo')
    setInterval(() => {
      if (this.session.countdown.type !== 1) return (this.countDownMinText = '')
      const ex = parseInt((new Date(this.session.countdown.deadline).getTime() - Date.now()) / 1000)
      if (ex < 0) return (this.countDownMinText = 'Time is up')
      if (ex <= 24 * 3600) {
        this.countDownMinText = this.countdownFormat(ex)
      } else {
        this.countDownMinText = new Date(this.session.countdown.deadline).toLocaleString()
      }
    }, 1000)
  },
  mounted() {
    this.init()
  },
  watch: {
    groups: {
      handler(val) {
        if (_.isEmpty(val) && this.showGroupDialog) {
          this.showGroupDialog = false
          this.$alert('The teacher closed all groups', 'prompt', {
            confirmButtonText: 'Done',
            callback: (action) => {},
          })
        }
      },
      deep: true,
    },
    roomGroup: {
      handler(val, old) {
        if (!old || !val) return console.log('rooms groups, no change')
        if (val._id === old?._id) return console.log('rooms groups, no change')
        if (val._id) this.$message(`You have been added to the "${val.name}" group`)
        else if (this.rooms.groups.length > 0) {
          if (this.isResponseShow) {
            this.$alert('You have been removed from the group so you can no longer view others’ responses', '', {
              confirmButtonText: 'Got it',
            })
            // 移除分组，如果正在查看分组答案，需要关闭
            this.toggleResponse()
          } else {
            this.$message(`You have been removed from the group by the teacher`)
          }
        } else {
          if (this.isResponseShow) {
            this.$alert('The teacher has disbanded all groups so you can no longer view others’ responses', '', {
              confirmButtonText: 'Got it',
            })
            // 移除分组，如果正在查看分组答案，需要关闭
            this.toggleResponse()
          } else {
            this.$message(`The teacher has disbanded all groups`)
          }
        }
      },
      deep: true,
    },
    currentPageIndex() {
      this.doAfterPageChange()
      if (this.isStudentPaced) {
        localStorage.setItem('studentLastPage', this.currentPageIndex)
        this.isResponseShow = !!this.isResponseShowMap[this.currentPageIndex]
      }
      this.hideStudentModal()
      this.setCurrentPreviewData({type: '', url: '', noSync: 1})
      EventBus.$emit('hideCommentModal')
    },
    session() {
      if (!this.isStudentPaced) {
        this.isResponseShow = false
      } else {
        this.isResponseShow = !!this.isResponseShowMap[this.currentPageIndex]
      }
      console.log('isStudentPaced hideCommentModal', this.isStudentPaced)
      EventBus.$emit('hideCommentModal')
    },
  },
  methods: {
    ...mapActions('student', ['initStudent', 'setStudentPageIndex', 'updateAnswerdPage', 'setAllAnswerdList', 'setStudentFeedBackComment']),
    ...mapActions('teacher', ['setFeedBackList', 'setFeedBackAnswerIds']),
    ...mapActions('teacher', ['setCurrentPreviewData']),
    ...mapActions(['setCountDown']),
    isNotAvailable() {
      return this.session.del === true || this.session.status === 'close' || this.isBlock || this.canceled
    },
    async getCountdownStart() {
      this.countdownStart = await App.service('rooms').get('countdownStart', {query: {sid: this.session.sid}})
    },
    async setCountdownStart() {
      this.countdownStart = await App.service('rooms').patch('countdownStart', {sid: this.session.sid})
    },
    async init() {
      if (!this.session._id) {
        await sleep(100)
        this.init()
        return
      }
      if (this.isAnonymous && this.isNotAvailable()) {
        return
      }
      let startTimeDate = new Date(this.session.start)
      if (window.countDownIt) clearInterval(window.countDownIt)
      window.countDownIt = setInterval(async () => {
        if ([1, 2].includes(this.session.countdown?.type)) {
          this.countDownTimeText = this.countdownTime()
          if (
            (this.countdownStart &&
              this.session.status == 'student' &&
              this.countDown.status &&
              new Date().getTime() > new Date(this.countdownStart).getTime() + this.session.countdown.down * 60 * 1000) ||
            (this.session.countdown?.deadline && new Date().getTime() > new Date(this.session.countdown.deadline).getTime())
          ) {
            this.lock_all_pages = true
          } else {
            //if (this.session.countdown?.studentCtrl && this.countDown.status !== 1) {
            if (
              !this.isWaitingStart &&
              this.session.status == 'student' &&
              this.session.sessionType === 'student' &&
              this.session.countdown.type == 2 &&
              !this.countdownStart
            ) {
              this.startCountdownVisible = true
            } else {
              this.startCountdownVisible = false
            }
            this.lock_all_pages = false
          }
        } else {
          this.lock_all_pages = false
          this.startCountdownVisible = false
        }

        if (this.session.status === 'close' && this.countDown.status) {
          this.setCountDown({status: 0})
        }

        if (!this.countDown.status && this.session.status === 'student' && this.session.sessionType === 'student' && this.countdownStart) {
          console.log('<===========retore individual countdown')
          this.setCountDown({time: this.session.countdown?.down, status: 1})
        }

        // has not started
        if (startTimeDate.getTime() > Date.now()) {
          this.isWaitingStart = true
          const xt0 = startTimeDate.getTime() - Date.now()
          this.displayTime = this.countdownFormat(xt0 / 1000)
        } else if (this.session.del === false) {
          if (this.isWaitingStart) {
            this.isWaitingStart = false
          }
          if (this.isNotJoin && window.Auser) {
            this.isNotJoin = false
            await this.initStudent(window.Auser)
          }
        }
      }, 1000)

      if (!this.session.start) {
        this.isWaitingStart = false
        console.log(this.session, '<====session.start is not exist====')
        return
      }
      /*
      if (startTimeDate.getTime() < Date.now() && this.session.del === false) {
        await this.initStudent(window.Auser)
        this.isWaitingStart = false
      }
      */
      window._this = this
      window._state = this.$store.state
      window._store = this.$store
      App.service('auth').removeAllListeners(['patched'])
      App.service('auth').on('patched', (rs) => {
        if (rs.to === 'teacher') return // 推送给老师的，学生端无需处理
        try {
          if (rs.method) this[rs.method](rs.data)
          //if (rs.commit && !(rs.commit === 'setCountDown' && this.session.countdown?.studentCtrl)) this.$store.commit(rs.commit, rs.data)
          if (rs.commit && !(rs.commit === 'setCountDown' && this.session.status == 'student')) this.$store.commit(rs.commit, rs.data)
        } catch (error) {
          console.log(error)
        }
      })
      this.unread = this.$store.state.unreadStudentCommentIds?.length > 0
      this.screenWidth = document.body.clientWidth
      this.screenHeight = document.body.clientHeight
      this.currentScreenWidth = document.body.clientWidth
      if (this.getQuestion && this.getQuestion.type !== 'draw') {
        this.smallWindow = this.currentScreenWidth < this.smallWindowValue
      } else {
        this.smallWindow = false
      }
      window.onresize = () => {
        return (() => {
          this.currentScreenWidth = document.body.clientWidth
          if (this.getQuestion && this.getQuestion.type !== 'draw') {
            this.smallWindow = this.currentScreenWidth < this.smallWindowValue
          } else {
            this.smallWindow = false
          }
        })()
      }
      // 同步对应的评估表启用状态配置
      if (this.isAnonymous) this.updateEvaluationStatus()
    },
    setLastPage() {
      // reset student last page
      if (this.session.status === 'student') {
        const lastPage = localStorage.getItem('studentLastPage')
        if (!lastPage) return
        this.$store.commit('student/setCurrentPageIndex', parseInt(lastPage))
      }
    },
    async startLocalCoutdown() {
      if (this.session.countdown?.down) {
        await this.setCountDown({time: this.session.countdown?.down, status: 1})
        await this.setCountdownStart()
        this.startCountdownVisible = false
      } else {
        console.warn('<==========startLocalCoutdown failed ======')
      }
    },
    cancelLocalCoutdown() {
      if (this.$route.query.back) {
        setTimeout(() => {
          window.location.href = `https://${PPT.isDev ? 'dev.' : ''}classcipe.com/v2${this.$route.query.back}#sessions`
        }, 1)
      } else {
        try {
          window.close()
        } catch (e) {
          console.log(e, '<=================')
        }
        setTimeout(() => {
          window.location.href = `https://${PPT.isDev ? 'dev.' : ''}classcipe.com/v2`
        }, 100)
      }
    },
    countdownFormat(ex) {
      const srr = []
      const h = parseInt((ex % 86400) / 3600)
      const m = parseInt((ex % 3600) / 60)
      const s = parseInt(ex % 60)
      srr.push(h < 10 ? '0' + h : h, m < 10 ? '0' + m : m, s < 10 ? '0' + s : s)
      return (ex > 86400 ? parseInt(ex / 86400) + 'Day ' : '') + srr.join(':')
    },
    countdownTime() {
      if (this.countDown.status !== 1) return ''
      let ex = parseInt((new Date(this.session.countdown.deadline).getTime() - new Date().getTime()) / 1000)
      //if (this.session.countdown?.studentCtrl) {
      if (this.session.status == 'student') {
        if (this.session.sessionType == 'student') {
          if (this.countdownStart) {
            ex = parseInt((new Date(this.countdownStart) - new Date().getTime()) / 1000) + this.session.countdown.down * 60
          } else {
            return ''
          }
        } else if (this.countDown.end) {
          ex = parseInt((this.countDown.end - new Date().getTime()) / 1000)
        }
      }
      let sec = ex % 60
      let min = parseInt(ex / 60)
      if (min === 0 && sec <= 0) return 'Time is up' // countdown over
      // if (min > 0) return `${min} min`
      // if (min === 0) return `${sec} sec`
      if (sec < 0 || min < 0) return 'Time is up'
      if (min < 10) min = '0' + min
      if (sec < 10) sec = '0' + sec
      return `${min}:${sec}`
    },
    async getStudentToOtherComments() {
      const rs = await App.service('comments').find({query: {sid: this.class_id, $limit: 1000, to: currentUserId}})
      this.setFeedBackList(rs.data)
      this.setFeedBackAnswerIds(rs.data.map((v) => v.rid))
    },
    toggleResponse() {
      EventBus.$emit('hideCommentModal')
      const nextStatus = !this.isResponseShowMap[this.currentPageIndex]
      this.isResponseShowMap[this.currentPageIndex] = nextStatus
      this.isResponseShow = nextStatus
      this.hideStudentModal()
    },
    changeTipShow() {
      this.showTip = !this.showTip
    },
    loginWithoutToken() {
      this.isVisitor = true
      this.showLoginDialog = true
      console.log('login without token!', this.showLoginDialog)
    },
    async guestJoinRoom(user) {
      // 缓存匿名登录信息
      return await this.initWithToken(user)
      this.showLoginDialog = false
    },
    async googleLogin() {
      await this.initWithToken()
      this.showLoginDialog = false
    },
    openWebsitePage(item) {
      console.log('open', item.url)
      var strWindowFeatures = 'width=1500,height=750,menubar=yes,location=yes,resizable=yes,scrollbars=true,status=true,top=100,left=100'
      window.open(item.url, '_blank', strWindowFeatures)
    },
    loadDiyPainter() {
      this.$nextTick(() => {
        const selector = document.getElementById('diycolor_comment')
        // console.log(selector, "selector");
        colorSelector.init(selector)
      })
    },
    changeShowOrAnswer() {
      this.isShowQuestion = !this.isShowQuestion
    },
    getWidthPercent() {
      const type = this.getQuestion?.type
      // if (this.questionModalVisiable) return "30%";
      if (type === 'draw') return '100%'
      if (type === 'comment' || type === 'media') return '350px'
      //if (type === 'text')  return null
      if (this.smallWindow) {
        /*
        if (this.isShowQuestion) {
          return '0%'
        } else {
          return '100%'
        }
        */
      }
      return '40%'
    },
    async initWithToken(user) {
      showLoading()
      if (!user) return this.goToLoginAfterLoginout()
      if (user?.anonymous && !this.isAnonymous) {
        await App.authentication.removeAccessToken()
        return this.goToLoginAfterLoginout()
      }
      // 获取会话设置
      this.uname = window.currentNickname = user?.nickname
      window.uid = user?.email
      window.Auser = user
      window.currentUserId = user?._id
      window.class_id = this.class_id
      await this.afterLogin(user)
      await this.getCountdownStart()
      await this.$store.dispatch('getClassInfo')
      //await this.initStudent(user)
      await this.$store.dispatch('initMaterials')
      await this.$store.dispatch('initPageConf', true)
      hideLoading()
    },
    async afterLogin() {
      if (!this.session.sid) {
        this.wrongClassCodeVisible = true
        hideLoading()
        return
      }
      // 获取学生对其他学生的评论数据
      await this.getStudentToOtherComments()
      let rs = await App.service('comments').find({query: {sid: class_id, to: currentUserId, $limit: 1000}})
      this.setStudentFeedBackComment(rs.data)
      console.log(rs.data, 'setStudentFeedBackComment')
      this.studentCommentLoaded = true
      // vuex缓存答案
      rs = await App.service('response').find({query: {sid: class_id, $limit: 2000}})
      this.setAllAnswerdList(rs.data)
      this.getItemData()
      this.loadDiyPainter()
    },
    // Profile过期了, 就走跳转或者匿名登录
    goToLoginAfterLoginout() {
      console.warn('need login', this.isOk, this.isAnonymous, this.user)
      if (this.isAnonymous) {
        this.isOk = true
        this.isVisitor = true
        this.showLoginDialog = true
      } else {
        const url =
          `https://${PPT.isDev ? 'dev' : 'my'}.classcipe.com/v2/login?from=roomStudent&role=student&call=` +
          encodeURIComponent(location.origin + location.pathname)
        location.href = url
      }
    },
    isPageLocked() {
      if (this.session.status == 'student') return false
      return this.$store.getters['teacher/isLocked'](this.currentPageIndex)
    },
    getCurrentPageAnswer() {
      if (!this.getQuestion) return
      const {page, type} = this.getQuestion
      if (type !== 'comment') {
        return this.myPageAnswerList
      } else {
        // comment remark 特殊，数据不在answer内
        return this.$store.state.remark.allRemarks.filter((item) => item.page_id === page)
      }
    },
    checkCurrentAnswerd() {
      this.currentAnswerd = false
      if (!this.getQuestion) return
      this.answerList = this.getCurrentPageAnswer()
      this.currentAnswerd = this.answerList.length > 0
      if (this.currentAnswerd) {
        this.updateAnswerdPage(this.currentPageIndex)
        if (this.answerList[0].type == 'media') {
          this.link = this.answerList[0].content
        }
      }
    },
    getItemData() {
      this.$nextTick(() => {
        this.checkCurrentAnswerd()
        this.isShowRightAnswer()
        if (this.getQuestion && this.getQuestion.type !== 'draw') {
          this.smallWindow = this.currentScreenWidth < this.smallWindowValue
        } else {
          this.smallWindow = false
        }
      })
    },
    pageChange(page) {
      const nextPage = parseInt(page)
      if (this.currentPageIndex != nextPage) {
        this.setStudentPageIndex(nextPage)
      } else {
        // console.log("已是当前页码，不用切换", "pageChange");
      }
    },
    doAfterPageChange() {
      this.getItemData()
      this.isShowRightAnswer()
      this.isShowQuestion = true
    },
    // ------------------------------------------
    showStudentModal() {
      // if(this.isResponseShow) return false
      if (!this.studentCommentLoaded) {
        return showToast('data not ready', 'warning')
      }
      if (!this.modalVisiable) {
        this.unread = false
        console.log('showStudentModal')
        EventBus.$emit(ModalEventsNameEnum.SHOW_STUDENT_MODAL, true)
      } else if (this.unread) {
        // 打开状态下有更新
        EventBus.$emit(ModalEventsNameEnum.SHOW_STUDENT_MODAL_REFRESH)
      } else {
        EventBus.$emit(ModalEventsNameEnum.SHOW_STUDENT_MODAL, false)
      }
      this.modalVisiable = !this.modalVisiable
    },
    hideStudentModal() {
      EventBus.$emit(ModalEventsNameEnum.SHOW_STUDENT_MODAL, false)
      this.modalVisiable = false
    },
    isShowRightAnswer() {
      if (!this.getQuestion || this.getQuestion.type != 'choice') {
        this.showCorrect = false
        return false
      }
      const result = this.getCurrentPageAnswer()
      if (result && result.length > 0) {
        const {locked} = result[0]
        this.showCorrect = locked && locked !== 'false' ? true : false
        if (this.showCorrect) {
          this.showCorrect = !!this.getQuestion.options.find((o) => !!o.on)
        }
        return this.showCorrect
      }
      this.showCorrect = false
      return false
    },
    getDeadLineStr(countDownTimeSeconds) {
      const countDownTime = Math.ceil(countDownTimeSeconds / 60)
      if (countDownTime < 2) {
        return `${countDownTimeSeconds} s`
      } else if (2 <= countDownTime < 60) {
        // 以下是分钟
        return parseInt(countDownTime) + ' min'
      } else if (countDownTime < 24 * 60) {
        return parseInt(countDownTime / 60) + ' hour/' + parseInt(countDownTime % 60) + ' min'
      } else {
        return (
          parseInt(countDownTime / (60 * 24)) +
          ' day/' +
          parseInt((countDownTime % (60 * 24)) / 60) +
          ' hour/' +
          parseInt((countDownTime % (60 * 24)) % 60) +
          ' min'
        )
      }
    },
    hasS(count) {
      if (count > 1) return 's '
      return ' '
    },
    showFullScreen(isFull) {
      this.fullScreen = isFull
    },
    answerCall() {
      this.updateAnswerdPage(this.currentPageIndex)
      this.currentAnswerd = true
    },
    showGroupModal() {
      this.showGroupDialog = true
    },
    eOthers() {
      const url = `https://${PPT.isDev ? 'dev' : 'my'}.classcipe.com/user/auth-redirect?redirect=/student/peer-evaluation/${this.class_id}&token=${this.token}`
      window.open(url)
    },
    eSelf() {
      const url = `https://${PPT.isDev ? 'dev' : 'my'}.classcipe.com/user/auth-redirect?redirect=/student/evaluation/${this.class_id}&token=${this.token}`
      window.open(url)
    },
    handleBack() {
      this.showBackConfirm = true
    },
    async backToClass() {
      if (this.isAnonymous) await App.authentication.removeAccessToken()
      location.href = `https://${PPT.isDev ? 'dev' : 'www'}.classcipe.com`
    },
    updateEvaluationStatus() {
      if (this.updateEvaluationStatusTimer) {
        clearTimeout(this.updateEvaluationStatusTimer)
      }
      // 课堂的token带.号
      if (this.class_id && this.token && this.token.indexOf('.') > -1) {
        // 只有非open-session才显示评估
      } else {
        this.updateEvaluationStatusTimer = setTimeout(() => {
          this.updateEvaluationStatus()
        }, 1000)
      }
    },
  },
}
</script>

<style lang="scss">
.custom-dialog.el-dialog {
  margin-top: 2rem !important;
  padding: 0;
  width: 80%;
  border-radius: 8px;
}
</style>
<style scoped>
/*
@media screen and (max-width: 800px) {
   .student-right{
     width: 0%;
   }
   .show-question.student-right {
     width: 100%;
   }
}
@media screen and (min-width: 800px) {
   .student-right {
   }
}
*/
.website--content {
  width: 100%;
  height: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
}
.website--content:hover {
  background-color: #15983c;
  color: white;
}
#diycolor_comment {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: none;
  opacity: 0;
  transition: opacity 150ms linear;
  z-index: 9999;
}
.student-main {
  flex: 1;
  display: flex;
  padding-bottom: 5px;
  box-sizing: border-box;
  overflow: hidden;
}
.student-right {
  height: 100%;
  position: relative;
  background-color: rgba(211, 220, 230, 1);
}
.student-left {
  background-color: #f4f4f4;
  box-sizing: border-box;
  padding: 0;
  flex: 1;
}
.st-ppt-outer {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.ppt-out-line {
  border: 1px solid #707070;
  box-shadow: 0px 10px 12px rgba(126, 126, 126, 0.16);
  box-sizing: border-box;
}
.icon {
  cursor: pointer;
  /* z-index: 999;
  position: fixed;
  top: 60px;
  right: 45%; */
}
.tip_area {
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 5%;
  z-index: 9999;
}

.web_site_icon {
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10%;
  z-index: 9999;
  display: flex;
}
.tip_area_popover {
  cursor: pointer;
  z-index: 999;
  color: #313333;
  height: auto;
  position: fixed;
  top: 50px;
  right: 1%;
  border-radius: 5px;
  width: 200px;
  background-color: #ffffff;
  min-height: 50px;
  padding: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.deadline_info {
  background-color: red;
  opacity: 0.6;
  height: 43px;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
}
.top_btn {
  height: 50px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  display: flex;
  z-index: 999;
}
.online_status {
  width: 50px;
  height: 43px;
  font-size: 30px;
  line-height: 43px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin-right: 20px;
}
.page {
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 60px;
  box-sizing: border-box;
}
.student-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.block {
  width: 100%;
  height: 100%;
}
.scroll-student {
  max-height: 700px;
  background-color: #999;
  overflow-y: scroll;
}
.scroll-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 99999;
}
.sfooter {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #d9dfe4;
  z-index: 1999;
}
.sfooter div {
  margin: 0 20px;
}
.page_index {
  position: relative;
  flex: 1;
  padding-top: 20px;
}
.checkboxs {
  margin-right: 10px;
}
.website {
  width: 90%;
  height: 100%;
}
.full_screen {
  background-color: #000000;
  opacity: 0.98;
  z-index: 8888;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
</style>
