<template>
  <div class="page-join flex_v">
    <div class="line--text" v-if="session.className">
      <span class="class--text">Class name</span>
      <span class="class--value">{{session.className}}</span>
    </div>
    <div class="line--text" v-if="roomGroup">
      <span class="class--text">My group name</span>
      <span class="class--value">{{roomGroup.name}}</span>
    </div>
    <div class="flex_v group-list">
      <div class="flex_v" v-for="(group, i) in rooms.groups" :key="i">
        <div class="flex_j -h">
          <div class="flex_j flex_c">
            <div>{{group.name}}</div>
            <div>({{members(group).length}}/{{rooms.groupMax}})</div>
          </div>
          <div v-if="!roomGroup && members(group).length < rooms.groupMax" class="flex_c -btn">
            <el-button size="mini" v-loading="group._loading" class="theme_bg" type="primary" @click="joinGroup(group)">Join Group</el-button>
          </div>
        </div>
        <div class="flex_h -ul">
          <div class="flex_l flex_ac -li" v-for="(one, mi) in members(group)" :key="mi">
            <student-avatar :student="one"></student-avatar>
            <div class="-name text-over">{{one.nickname}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions,mapState,mapGetters } from 'vuex'
import StudentAvatar from '../common/studentAvatar';
export default {
  components: { StudentAvatar },
  computed: {
    ...mapState(['session']),
    ...mapGetters('student', ['roomGroup', 'roomMe']),
    ...mapState('student', ['rooms', 'user']),
    myGroup() {
      const me = this.rooms.members.find(v => v._id === this.user._id)
      const group = this.rooms.groups.find(v => v._id === me.group)
      return group
    }
  },
  watch: {
    'rooms': {
      async handler(o) {
        this.$forceUpdate()
        console.log('rooms patch')
      }, deep: true, immediate: true
    }
  },
  data() {
    return {
      name: "",
      nameMouseStatus: false
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions('student', ['joinGroup']),
    anonymousBtnClicked() {},
    onNameMouseStatus(status) {
      this.nameMouseStatus = status;
    },
    members(group) {
      return this.rooms.members.filter(v => v.group === group._id)
    },
  }
};
</script>

<style lang="scss" scoped>
.group-list{
  flex:1;
  overflow-y: scroll;
  .-h{
    padding: 0 0.5rem;
    min-height: 3rem;
  }
  .-ul{
    background-color:#ecf5ff;
    flex-wrap: wrap;
    min-height: 3rem;
  }
  .-li{
    background-color:antiquewhite;
    border-radius: 1.5rem;
    height: 2.6rem;margin: 0.5rem;padding: 0 0.5rem;
    min-width: 7rem;width: 9rem;
  }
}
</style>
<style scoped>
.el-dialog__body{
  display: flex;
  overflow: hidden;
  flex:1;
}
.name--holder {
  display: flex;
  font-size: 14px;
  line-height: 16px;
  color: #808191;
  opacity: 1;
  height: 40px;
  align-items: center;
}
.btn--class {
  width: 280px;
  height: 50px;
  margin-top: 50px;
  background: #14bc95;
  opacity: 1;
  border-radius: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.group--text {
  width: 60px;
  height: 14px;
  font-size: 12px;
  line-height: 16px;
  color: #808191;
  opacity: 1;
}

.line--item {
  width: 412px;
  min-height: 70px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #e4e4e4;
  border: 2px solid #15c39a00;
  border-radius: 10px;
  margin-top: 10px;
}
.line--item:hover {
  width: 412px;
  /* height: 70px; */
  /* background: #ffffff; */
  border: 2px solid #15c39a;
  opacity: 1;
}
.class--value {
  height: 19px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #323232;
  opacity: 1;
  margin-left: 20px;
}
.class--text {
  height: 19px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #15c39a;
  opacity: 1;
}
.line--text {
  height: 3rem;
  display: flex;
  align-items: center;
}

.cls-1 {
  fill: #2c2a50;
  font-size: 24px;
}

.cls-1-content {
  fill: #7174a0;
  font-size: 16px;
}
.page-join {
  background-color: white;
  flex:1;overflow: hidden;
  max-height: 500px;
}
</style>


