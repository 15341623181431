<template>
  <el-dialog v-if="!user._id" class="flex_c guestLogin" title="Welcome to Your Lesson!"
    :visible.sync="dialog" :close-on-click-modal="false" :show-close="false" center>
    <div class="flex_v flex_c">
      <input class="fullname" placeholder="Full name" v-model="name" @keyup.enter="joinRoomBtn"/>
      <el-button type="primary" v-loading="loading" @click="joinRoomBtn"><b>Join</b></el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    guestJoinRoom: { type: Function }
  },
  computed: {
    ...mapState(['session']),
    ...mapState('student', ['user']),
  },
  data() {
    return {
      dialog: true,
      loading: false,
      name: ""
    }
  },
  methods: {
    ...mapActions("student", ['setUser']),
    async joinRoomBtn() {
      if (!this.name || this.name.length < 1) return this.$message.warning("Please input your name")
      if (this.loading) return
      this.loading = true
      const rs = await App.service('auth').create({ guest: true, sid: this.$route.params.id, nickname: this.name })
      if (!rs) return
      if (rs.accessToken) await App.authenticate({ strategy: 'jwt', accessToken: rs.accessToken }, {query: { sid: this.session.sid, role: 'student' }})
      if (rs.user?.id) this.setUser(rs.user)
      await this.guestJoinRoom(rs.user)
      this.dialog = false
      this.loading = false
    }
  }
};
</script>
<style>
.guestLogin .el-dialog__title{
  color: #000000;
  font-weight: bolder;
  font-size: 2rem;
}
.guestLogin .el-dialog__header{padding-top:2rem;}
.guestLogin .el-dialog{border-radius: 1rem;}
</style>
<style scoped>
.welcome-page{padding: 2rem;border-radius: 1rem;}
.el-button{width: 50%;}
.fullname{width: 70%;border-radius: 1rem;margin-bottom: 1.5rem;background-color: #E4E4E4;
  border:0;padding: 1rem 1.5rem;color: #333;font-size: 1.5rem;font-weight: bolder;}
</style>


