<template>
  <div class="flex1 flex_v" style="position: relative;">
    <div class="flex_c h_2">Multiple choice</div>
    <el-checkbox-group class="flex_v" style="overflow-y: scroll;" :min="1" v-model="formData" :disabled="showCorrect" @change="handleCheckedValueChange">
      <div class="item" v-for="(item, i) in getQuestion.options" :key="i">
        <el-checkbox :label="item._id" :value="item._id" class="rabox" :title="item.val">{{getAnswerText(item)}}</el-checkbox>
      </div>
    </el-checkbox-group>

    <template v-if="formData.length > 0">
      <div v-if="showCorrect" class="flex_ac pd_l1 h_2 c_green">Correct answer:</div>
      <template v-if="showCorrect">
        <template v-for="(item, i) in getQuestion.options">
          <div v-if="item.on" class="flex_ac ta_l pd_l1 c_green" :key="i">
            <span>{{getAnswerText(item)}}</span>
          </div>
        </template>
      </template>
      <el-switch class="pd_l1" v-if="hasAnswer()" v-model="showCorrect" :disabled="showCorrect" active-color="#13ce66" inactive-color="#999" @change="changeLocked" active-text="show answers"/>
        <!--
      <div class="pd_l1 refresh-line" v-show="showRefreshAnswer">
        <span>Correct answer updated!</span>
      </div>
        -->
    </template>
  </div>
</template>
<style>
</style>
<style scoped>
.item {
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
}
.refresh-line{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
<script>
import { mapState, mapGetters} from "vuex";
export default {
  props: {
    answerChoice: { type: Function, default: () => {}}
  },
  computed: {
    ...mapGetters(['getQuestion', 'getPageId']),
    ...mapGetters('student', ['myPageAnswerList']),
    ...mapState({
      elements: state => state.student.elements,
    }),
    ...mapState(['session']),
    question() {
      return this.session.questions?.find(o => o.page = this.getPageId) || {}
    }
  },
  data() {
    return {
      lastOptionsStr: '',
      processi: 0,
      loading: false,
      formData: [],
      optIds: [],
      one: {},
      showCorrect: false,
      tipText: null,
      showTips: false,
      showRefreshAnswer: false
    };
  },
  watch: {
    getPageId: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
    getQuestion: {
      handler(val) {
        if (this.loading || JSON.stringify(val.options) === this.lastOptionsStr || this.lastPageId !== val._id) return
        this.lastOptionsStr = JSON.stringify(val)
        this.lastPageId = val._id
        this.showRefreshAnswer = true
        this.$notify.info({ title: 'Notify', message: 'Correct answer updated!', duration: 3000 });
      },
      deep: true
    }
  },
  async mounted() {
    this.loadData()
    if (this.elements) {
      for (let i = 0; i < this.elements.length; i++) {
        if (this.elements[i].type == "tip") {
          this.tipText = this.elements[i].tip;
        }
      }
    }
    if (this.process) this.process()
  },
  destroyed() {
    this.process = null
  },
  methods: {
    loadData() {
      this.loading = true
      this.showCorrect = false
      this.one = {}
      this.formData = []
      this.showRefreshAnswer = false
      this.lastOptionsStr = JSON.stringify(this.getQuestion.options)
      this.lastPageId = this.getQuestion._id
      this.optIds = this.getQuestion.options.map(v => v._id)
      const rs = this.$store.getters['student/myPageAnswerList']
      if (!rs[0]) return
      this.one = rs[0]
      this.formData = [...this.one.answer??[]]
      const { locked } = this.one;
      this.showCorrect = (locked && locked != "false") ? true : false;
      if (this.showCorrect) this.showCorrect = this.hasAnswer();
      console.warn(this.getQuestion.options, this.formData, this.showCorrect, locked, this.hasAnswer())
      this.loading = false
    },
    async process() { // 监控答案变化，0.8秒无操作提交答案
      let isChange = false
      if (this.processi > 8) {
        this.formData.sort()
        isChange = JSON.stringify([this.formData, this.showCorrect]) !== JSON.stringify([this.one.answer ?? [], this.one.data?.locked ?? false])
        this.processi = 0
        console.log('check checkbox change', isChange)
      }
      if (isChange && this.formData) {
        await this.answerChoice({...this.one }, { content: this.getAnswerContent(), answer: this.formData, locked: this.showCorrect })
        this.loadData()
      } else {
        this.processi++
      }
      setTimeout(() => {
        if (this.process) this.process()
      }, 100)
    },
    async handleCheckedValueChange(value) {
      this.processi = 0
    },
    showTip() {
      this.showTips = !this.showTips;
    },
    async changeLocked() {
      this.processi = 0
    },
    hasAnswer() {
      return !!this.getQuestion.options.find(o => o.on)
    },
    getAnswerContent() {
      const arr = []
      this.getQuestion.options.map((item) => {
        if (this.formData.includes(item._id)) arr.push(this.getAnswerText(item))
      })
      return arr.join('\n')
    },
    getAnswerText(item) {
      // console.log(item);
      let AA = ["A", "B", "C", "D", "E", "F", "G"]
      return AA[this.optIds.indexOf(item._id)] + ": " + item.val
    }
  }
};
</script>
