<template>
  <div class="waiting-start flex_c">
    <div class="wait-img">
      <img  src="../../assets/picture/wating.png" />
    </div>
    <div class="wait-seconds">
      <div class="wait-title-first">
        <img  src="../../assets/picture/pleasewait.png" />
      </div>
      
      <div class="wait-seconds-num" v-if="displayTime">
        <img  src="../../assets/picture/wlock.png" height="50px" style="margin-right:20px"/>
         ({{displayTime}})
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "waitingStart",
  props: {
    displayTime: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.waiting-start {
  padding: 20px;
}

.wait-img img {
  height: 300px;
}

.wait-title-first img {
  height: 35px;
}

.wait-title-mid, .wait-seconds-num {
  font-size: 25px;
  padding-left: 10px;
  color: #070707;
  font-weight: bold;
  font-family: Trebuchet MS;
}

.wait-seconds-num {
  color: rgba(21, 195, 154, 1);
  float: left;
  line-height: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
}

</style>
