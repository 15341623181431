<template>
  <div class="index_page flex1" style="width: 100%; height: 100%">
    <numberIndex class="flex1" v-if="getQuestion.type === 'number'" v-bind="$attrs" :answerText="answerText" :addAnswerText="addAnswerText" />
    <textItem class="flex1" v-else-if="getQuestion.type === 'text'" v-bind="$attrs" :answerText="answerText" :addAnswerText="addAnswerText" />
    <template class="flex1" v-else-if="getQuestion.type === 'choice'">
      <checkboxItem v-if="getQuestion.multi" v-bind="$attrs" :answerChoice="answerChoice" />
      <radioItem v-else v-bind="$attrs" :answerChoice="answerChoice" />
    </template>
    <website class="flex1" v-else-if="getQuestion.type === 'website'" v-bind="$attrs" />
    <!--
    <draw class="flex1" v-else-if="getQuestion.type === 'draw'" v-bind="$attrs" :sendCanvas="sendCanvas" :sendDrawText="sendDrawText"/>
    -->
    <excalidraw class="flex1" v-else-if="getQuestion.type === 'draw'" v-bind="$attrs" :sendCanvas="sendCanvas" />
    <studentAudioItem class="flex1" v-else-if="getQuestion.type === 'media'" v-bind="$attrs" :addMediaAnswer="addMediaAnswer" />
    <studentRemark class="flex1" v-else-if="getQuestion.type === 'comment'" v-bind="$attrs" :addResponse="addResponse" />
  </div>
</template>
<script>
import {mapState, mapGetters} from 'vuex'
import draw from './draw.vue'
import excalidraw from './excalidraw.vue'
import numberIndex from './numberIndex.vue'
import textItem from './textItem.vue'
import radioItem from './radioItem'
import checkboxItem from './checkboxItem'
import website from './website.vue'
import studentAudioItem from './studentAudioItem.vue'
import studentRemark from './studentRemark.vue'
export default {
  props: {
    answerCall: {type: Function, default: () => {}},
  },
  computed: {
    ...mapGetters(['getQuestion']),
  },
  components: {numberIndex, textItem, radioItem, checkboxItem, website, draw, excalidraw, studentAudioItem, studentRemark},
  created() {
    console.log('student index created', this.getQuestion)
  },
  methods: {
    // 发送text
    async addResponse(one, _page, _type) {
      if (!this.getQuestion) return console.warn('getQuestion error')
      if (!one.content) return console.warn('no answer!')
      const {page, type} = this.getQuestion
      if (!(_page && _type)) {
        _page = page
        _type = type
      }
      const post = {sid: this.$route.params.id, uid: currentUserId, nickname: currentNickname, page: _page, type: _type, ...one}
      const rs = await this.$store.dispatch('student/upSertResponse', post)
      Object.assign(one, rs)
      console.log('addResponse ok', one, rs)
      if (this.answerCall) this.answerCall()
      return rs
    },
    async saveResponse(one) {
      const rs = await this.$store.dispatch('student/upSertResponse', one)
      if (!rs) return
      if (this.answerCall) this.answerCall()
      return rs
    },
    async addAnswerText(one) {
      if (!one.content) return
      return await this.addResponse(one)
    },
    async answerText(one) {
      await this.saveResponse(one)
    },
    compareData(one, data) {
      const tmp = {}
      for (const key of Object.keys(data)) {
        tmp[key] = one[key]
      }
      return JSON.stringify(tmp) === JSON.stringify(data)
    },
    async answerChoice(one, data) {
      // data: { answer, locked }
      if (!one?._id) one = {}
      if (this.compareData(one, data)) return console.log('same answer, not save', one?.answer, data.answer)
      Object.assign(one, data)
      !one._id ? await this.addResponse(one) : await this.saveResponse(one)
      this.$forceUpdate()
    },
    sendDrawText(one, textContent) {},
    async sendCanvas(one, base64Url, json) {
      if (base64Url) {
        const arr = await App.service('response').patch('upfile', [base64Url])
        if (arr.data) base64Url = arr.data[0] ?? ''
      }
      Object.assign(one, {content: base64Url})
      Object.assign(one, {json: json})
      return one._id ? await this.saveResponse(one) : await this.addResponse(one)
    },
    // 发送 media 答案
    async addMediaAnswer(content, _page, _type) {
      // content = {link, mediaType, fileName}
      return await this.addResponse({content}, _page, _type)
    },
  },
}
</script>
