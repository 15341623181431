var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widthlist"},[_c('div',{staticClass:"item"},[_c('span',{staticClass:"icon",style:(`width: 10px;height: 10px; background-color: ${
        _vm.widthValue === 3
          ? _vm.tabIndex == 3
            ? 'red'
            : _vm.color
          : 'rgb(212 208 208)'
      };border:1px solid ${
        _vm.widthValue == 3
          ? _vm.color == '#FFFFFF'
            ? 'red'
            : '#FFFFFF'
          : 'rgb(212 208 208)'
      }
      }`),on:{"click":function($event){return _vm.changeWidth(3, _vm.tabIndex)}}}),_c('span',{staticClass:"icontext"},[_vm._v("3")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"icon",style:(`width: 15px;height: 15px; background-color: ${
        _vm.widthValue === 5
          ? _vm.tabIndex == 3
            ? 'red'
            : _vm.color
          : 'rgb(212 208 208)'
      }; border:1px solid ${
        _vm.widthValue == 5
          ? _vm.color == '#FFFFFF'
            ? 'red'
            : '#FFFFFF'
          : 'rgb(212 208 208)'
      }`),on:{"click":function($event){return _vm.changeWidth(5, _vm.tabIndex)}}}),_c('span',{staticClass:"icontext"},[_vm._v("5")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"icon",style:(`width: 20px;height: 20px; background-color: ${
        _vm.widthValue === 10
          ? _vm.tabIndex == 3
            ? 'red'
            : _vm.color
          : 'rgb(212 208 208)'
      }; border:1px solid ${
        _vm.widthValue == 10
          ? _vm.color == '#FFFFFF'
            ? 'red'
            : '#FFFFFF'
          : 'rgb(212 208 208)'
      }`),on:{"click":function($event){return _vm.changeWidth(10, _vm.tabIndex)}}}),_c('span',{staticClass:"icontext"},[_vm._v("10")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"icon",style:(`width: 25px;height: 25px; background-color: ${
        _vm.widthValue === 20
          ? _vm.tabIndex == 3
            ? 'red'
            : _vm.color
          : 'rgb(212 208 208)'
      }; border:1px solid ${
        _vm.widthValue == 20
          ? _vm.color == '#FFFFFF'
            ? 'red'
            : '#FFFFFF'
          : 'rgb(212 208 208)'
      }`),on:{"click":function($event){return _vm.changeWidth(20, _vm.tabIndex)}}}),_c('span',{staticClass:"icontext"},[_vm._v("20")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"icon",style:(`width: 30px;height: 30px; background-color: ${
        _vm.widthValue === 30
          ? _vm.tabIndex == 3
            ? 'red'
            : _vm.color
          : 'rgb(212 208 208)'
      }; border:1px solid ${
        _vm.widthValue == 30
          ? _vm.color == '#FFFFFF'
            ? 'red'
            : '#FFFFFF'
          : 'rgb(212 208 208)'
      }`),on:{"click":function($event){return _vm.changeWidth(30, _vm.tabIndex)}}}),_c('span',{staticClass:"icontext"},[_vm._v("30")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }