<template>
  <div class="parent">
    <div class="input_parent" :class="{active: focusId == i}" v-for="(o, i) in formData" :key="i">
      <keyboard
        v-loading="loading && i === 'add'"
        type="textarea"
        autosize
        placeholder="Please input answer(s)"
        v-model="formData[i]"
        @input="onInputFn(i)"
        @focus="onFocusFn(i)"
        @blur="onBlurFn(i)"
        :disabled="showCorrect"
        :ref="'_txt_' + i"></keyboard>
      <div v-show="focusId !== i" class="_icon"><i class="el-icon-edit-outline"></i></div>
    </div>

    <div v-if="showCorrect" class="answer_text">{{ data.items[0].data.answer }}</div>
    <el-switch
      v-if="hasAnswer()"
      v-model="showCorrect"
      :disabled="showCorrect"
      active-color="#13ce66"
      inactive-color="#999"
      @change="changeLocked('text')"
      active-text="show answer" />
    <el-button v-if="showMore" type="text" @click="addInput()" :disabled="addDisable || showCorrect">+Add another option</el-button>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import keyboard from '@/components/common/keyboard.vue'
export default {
  components: {
    keyboard
  },
  props: {
    answerText: {type: Function},
    addAnswerText: {type: Function},
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
    answer: {type: Function, default: () => {}},
  },
  data() {
    return {
      loading: false,
      test:'πº÷×sdfsersfsdfwerasdf/±²¹²',
      focusId: '',
      maxCount: 5,
      formData: {},
      processi: 0,
      showMore: false,
      showCorrect: false,
    }
  },
  computed: {
    ...mapGetters(['getPageId']),
    ...mapGetters('student', ['myPageAnswerList']),
    addDisable() {
      return false
      return this.myPageAnswerList?.length >= this.maxCount
    },
  },
  watch: {
    getPageId: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
  },
  created() {},
  destroyed() {
    this.process = null
    //console.log('text destroyed')
  },
  mounted() {
    //console.log('text create', this.myPageAnswerList)
    setTimeout(() => {
      this.loadData(1)
    }, 500)
    this.process()
  },
  methods: {
    showMoreFn() {
      if (this.myPageAnswerList?.length === 5) return false
      for (const key in this.formData) {
        if (!this.formData[key].trim()) return false
      }
      return true
    },
    async loadData() {
      this.formData = {}
      for (const o of this.myPageAnswerList) {
        if (o.locked) this.showCorrect = true
        if (!this.formData[o._id]) this.formData[o._id] = o.content
      }
      if (Acan.isEmpty(this.myPageAnswerList)) this.formData.add = ''
      this.showMore = this.showMoreFn()
      await this.$forceUpdate()
      //console.warn(123, this.myPageAnswerList, this.formData)
    },
    onInputFn(i) {
      this.$forceUpdate()
      this.processi = 0
    },
    onFocusFn(i) {
      this.focusId = i
    },
    async addInput() {
      this.formData.add = ''
      this.showMore = this.showMoreFn()
      await this.$forceUpdate()
      this.$refs._txt_add[0].$el.firstChild.focus()
    },
    async onBlurFn(i) {
      if (this.focusId == i) {
        this.focusId = ''
      }
      if (i !== 'add') return
      //console.log('onBlurFn', this.formData)
      // 新增答案处理
      if (this.formData.add?.trim()) {
        this.loading = true
        await this.addAnswerText({content: this.formData.add, locked: this.showCorrect})
        //console.log('check text add:', this.formData.add)
        this.loading = false
      }
      this.loadData()
      if (Object.keys(this.formData).length > 1) delete this.formData.add
      this.showMore = this.showMoreFn()
    },
    async process() {
      // 监控答案变化，0.8秒无操作提交答案
      if (this.processi > 8) {
        this.processi = 0
        for (const _id of Object.keys(this.formData)) {
          if (_id === 'add') continue
          const old = this.myPageAnswerList.get(_id)
          if (this.formData[_id] === old?.content) continue // 答案无变化
          await this.answerText({_id, content: this.formData[_id], locked: this.showCorrect})
          this.loadData()
          //console.log('text change:', _id, this.formData[_id])
        }
      }
      this.processi++
      setTimeout(this.process, 100)
    },
    changeLocked(type) {
      for (const key in this.formData) {
        if (key === 'add') continue
      }
    },
    hasAnswer() {
      let answered = false
      if (!this.myPageAnswerList) return false
      for (const o of this.myPageAnswerList) {
        if (!_.isEmpty(o.content)) answered = true
      }
      return !_.isEmpty(this.data.items?.[0]?.data?.answer) && answered
    },
  },
}
</script>
<style>
.input_parent textarea::-webkit-input-placeholder {
  color: grey;
}
.input_parent textarea {
  background: rgba(200, 200, 200, 0.5);
}
.input_parent.active textarea{
  background: #fff !important;
}
</style>
<style scoped>
.parent {
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.input_parent {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.input_parent ._icon {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
}
.textblur {
  cursor: pointer;
}
.textblur:hover {
  background-image: url(../../assets/picture/bianji.png);
  background-position: bottom 4px right 10px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
}
.answer_text {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  line-height: 20px;
  background-color: #efefef;
  border: 1px solid #5f5f5f;
  border-radius: 5px;
  text-align: left;
}
el-input {
  color: #303133;
  font-family: 'PingFang SC';
}
el-button {
  font-family: 'Microsoft YaHei';
  font-size: large;
}
</style>
