<template>
  <div class="answerandstudents">
    <div class="dashboard">
      <div class="dashboardpage" >
        <div class="dash-second" >
          <div :class="`dash-second-left dash-border`">
            <teacherIndexItem v-bind="$attrs" v-if="getQuestion" :flag_1="true" :type="getQuestion.type"/>
          </div>
          <div class="dash-second-right dash-students dash-pad-students">
            <DashGroupStudents />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import teacherIndexItem from "../teacher/Index.vue";
import DashGroupStudents from '../teacher/dashGroupStudents.vue'
export default {
  components: {
    teacherIndexItem, DashGroupStudents
  },
  watch: {
    groupMemberNames() {
      this.$message('Your group member has changed！')
    }
  },
  computed: {
    ...mapGetters(['getQuestion']),
    ...mapGetters('student', ['groupMemberNames'])
  }
}
</script>
<style scoped>
.answerandstudents{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: red;
}
</style>