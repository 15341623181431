<template>
  <el-dialog class="flex_c" style="margin-top:-15vh;" title="Welcome to Your Lesson!" :close-on-click-modal="false" :visible.sync="dialog" center>
    <div class="flex_v flex_c">
      <el-button type="primary" @click="toZoom">Join Your Zoom Meeting</el-button>
      <p class="pd_t05" style="width: 16rem;text-align: center;">You will be in a waiting room until your teacher approves you.</p>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import zoomBtn from '@/components/zoomBtn';
export default {
  components: { zoomBtn },
  computed: {
    ...mapState(['session']),
  },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    toZoom() {
      this.dialog = false
      const { join_url } = this.session.zoom ?? {}
      if(!join_url) return
      window.open(join_url, 'zoom', 'height=600, width=800, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no')
    },
  }
};
</script>


