<template>
  <div class="networkError">
    <p style="font-weight: bolder">This code or link is no longer valid</p>
    <div class="tryingButton" @click="backToMain">Ok</div>
  </div>
</template>
<script>
export default {
  methods: {
    backToMain() {
      location.href = 'https://classcipe.com'
    }
  }
}
</script>
<style scoped>
.networkError{
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 50px 0;
}
.tryingButton{
      width: 120px;
    height: 40px;
    border-radius: 4px;
    background-color: rgb(155 0 46);
    font-size: 14px;
    line-height: 40px;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 20px;
    align-self: center;
    display: inline-block;
    font-weight: bolder;
}
</style>
