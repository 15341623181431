<template>
  <div class="flex1">
    <p class="flex1 remark-text" :style="`${item.content ? '' : 'color:#aaa;'}`" v-if="!isEdit" @click="edit">{{item.content || 'Deleted Response'}}</p>
    <recordText :item="item" :onSend="onSendFn" v-else :defaultText="item.content" :cancel="cancel"/>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import recordText from '../common/recordText.vue'
export default {
  components: { recordText },
  props: {
    onSend: { type: Function, default: () => null },
    item: { type: Object, default: () => {}},
    index: { type: Number }
  },
  data() {
    return {
      loading: false,
      isEdit: false
    }
  },
  created() {
  },
  methods: {
    ...mapActions("remark", [ "changeRemarkIndex" ]),
    edit() {
      this.changeRemarkIndex(this.index)
      this.isEdit = true
    },
    cancel() {
      if (this.loading) return
      this.isEdit = false
    },
    async onSendFn(...args) {
      this.loading = true
      await this.onSend(...args)
      this.loading = false
      this.isEdit = false
    },
  }
}
</script>
<style scoped>
.remark-text {
  word-break: break-word;
  line-height: 24px;
  color: #000000;
  text-align: justify;
}
</style>
