<template>
  <div v-show="currentTips.tip">
    <el-dialog
      :visible.sync="dashTipsModalVisiable"
      custom-class="custom-dialog"
      width="90%"
      :append-to-body="true"
    >
      <div class="copy_page">
        <div class="student-tips" v-if="tipsValue && tipsValue.length>0">{{ tipsValue }}</div>
        <div class="carousel-page">
          <div class="img-list-wrapper">
            <div class="img-list">
              <div class="img-item" v-for="(item, index) in videoUrlList" :key="'index' + index">
                <div class="img-box">
                  <video
                    width="360px"
                    height="210px"
                    :src="item.url"
                    v-if="isVideoType(item, 'video')"
                    preload="auto"
                    controls
                  ></video>
                  <iframe
                    width="360px"
                    height="210px"
                    v-if="isVideoType(item, 'iframe')"
                    id="item_player"
                    :src="item.url"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div
      :class="dashTipsModalVisiable ? 'button_area back_focus' : 'button_area'"
      style="margin-right: 20px"
    >
      <div class="meterialimage" @click="showDashTips">
        <div class="fullbgimg dash-tip"></div>
        <i v-show="unreadMap[currentPageIndex]" class="unread"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("student", ["currentTips"]),
    ...mapState("student", ["unreadMap"]),
    ...mapState({
      currentPageIndex: (state) => state.student.currentPageIndex,
    }),
  },
  watch: {
    currentTips() {
      this.setTipText();
    },
    currentPageIndex() {
      this.close();
    },
  },
  data() {
    return {
      tipsValue: "",
      dashTipsModalVisiable: false,
      videoUrlList: [
        // {
        //   url: "https://classcipe-resource.s3.ap-southeast-2.amazonaws.com/drivefile_1649859826036",
        //   type: "video",
        // },
        // {
        //   url: "https://www.youtube.com/embed/wAOgZZ3TZso?showinfo=0&modestbranding=1&rel=0",
        //   type: "iframe",
        // },
      ],
    };
  },
  mounted() {
    this.setTipText();
  },
  methods: {
    ...mapMutations("student", ["setUnreadMap"]),
    setTipText() {
      if (this.currentTips) {
        this.tipsValue = this.currentTips.tip;
        this.videoUrlList= this.currentTips.urls
      } else {
        this.tipsValue = "";
        this.videoUrlList= [];
      }
    },
    isVideoType(item, type) {
      console.log("item", item, type);
      if (item.type === type ||item.tpye === type) {
        return true;
      }
      return false;
    },
    showDashTips() {
      this.dashTipsModalVisiable = true;
      // 已读
      this.setUnreadMap([this.currentPageIndex, false]);
    },
    close() {
      this.dashTipsModalVisiable = false;
    },
  },
};
</script>

<style scoped>
.copy_page {
  width: 100%;
  /* max-height: 500px; */
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  padding-top: 1rem;
}
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.student-tips {
  display: flex;
  /* background-image: url(../../assets/picture/dash-tip.png);
  background-size: contain;
  background-repeat: no-repeat; */
  min-height: 62px;
  min-width: 62px;
  width: 100%;
  height: 67px;
  background: #f5f5f5;
  padding-left: 70px;
  line-height: 62px;
  align-self: center;
  margin: 0 10px 10px;
}
.dash-tip {
  display: none;
  background-image: url(../../assets/picture/dash-tip.png);
}
.back_focus {
  background-color: #fff;
  border-radius: 8px;
}
.unread {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: absolute;
  top: 0;
  right: 0;
  background: red;
}

.carousel-page {
  height: 300px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.img-list-wrapper {
  width: 100%;
}
.img-list {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.img-item {
  position: relative;
  height: 210px;
  width: 370px;
  border: 1px solid #fff;
  /* padding: 10px; */
  margin-bottom: 20px;
  margin-right: 20px;
}
.img-box {
  position: absolute;
  height: 210px;
  width: 370px;
}

.active-img-item {
  border: 1px solid #15c39a;
}
</style>
