<template>
  <div class="flex1 flex_v" style="position: relative; overflow-y: scroll">
    <p class="flex_c h_2">Single choice</p>
    <div class="item" v-for="(item, i) in getQuestion.options" :key="i">
      <el-radio v-model="formData" :disabled="showCorrect" :label="item._id" class="rabox" :value="item._id" @change="changAnswer">
        {{ getAnswerText(item, i) }}
      </el-radio>
    </div>
    <template v-if="!Acan.isEmpty(formData)">
      <div v-if="showCorrect" class="flex_l flex_v" style="line-height: 25px; color: green">
        <div class="flex1 pd_l1">Correct answer：</div>
        <template v-for="(item, i) in getQuestion.options">
          <div class="flex1 pd_l1 ta_l" :key="i">
            <span v-if="item.on">{{ getAnswerText(item, i) }}</span>
          </div>
        </template>
      </div>
      <div v-show="hasAnswer()" class="item" style="background: transparent">
        <el-switch :value="showCorrect" :disabled="showCorrect" active-color="#13ce66" inactive-color="#999" @change="changeLocked" active-text="show answer" />
      </div>
      <!--
      <div class="refresh-line" v-show="showRefreshAnswer">
        <span>Correct answer updated!</span>
      </div>
      -->
        <!-- <img width="40" height="40" src="../../assets/picture/refresh.png"  @click="ref1reshNewAnswer"/> -->
    </template>
  </div>
</template>
<style>
</style>
<style scoped>
.item {
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  line-height: 50px;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
}
.tag {
  color: red;
}
.refresh-line {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>

<script>
import {mapGetters} from 'vuex'
export default {
  props: {
    answerChoice: {type: Function, default: () => {}},
  },
  computed: {
    ...mapGetters(['getQuestion', 'getPageId']),
    ...mapGetters('student', ['myPageAnswerList']),
  },
  watch: {
    getQuestion: {
      handler(val) {
        if (this.loading || JSON.stringify(val.options) === this.lastOptionsStr || this.lastPageId !== val._id) return
        this.lastOptionsStr = JSON.stringify(val.options)
        this.lastPageId = val._id
        this.showRefreshAnswer = true
        this.$notify.info({title: 'Notify', message: 'Correct answer updated!', duration: 3000})
      },
      deep: true,
    },
    getPageId: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
  },
  data() {
    return {
      lastOptionsStr: '',
      optIds: [],
      one: {answer: null},
      formData: null,
      processi: 0,
      showCorrect: false,
      loading: false,
      lastPageId: '',
      showRefreshAnswer: false,
    }
  },
  async mounted() {
    //await sleep(500)
    this.loadData()
    this.process()
  },
  created() {},
  destroyed() {
    this.process = null
  },
  methods: {
    refreshNewAnswer() {
      this.showRefreshAnswer = false
    },
    loadData() {
      this.loading = true
      this.showCorrect = false
      this.one = {}
      this.formData = []
      this.showRefreshAnswer = false
      this.lastOptionsStr = JSON.stringify(this.getQuestion.options)
      this.lastPageId = this.getQuestion._id
      this.optIds = this.getQuestion.options.map((v) => v._id)
      const result = this.myPageAnswerList
      if (Acan.isEmpty(result)) return
      this.one = result[0]
      const {answer, locked} = result[0]
      this.formData = answer[0]
      this.showCorrect = locked && locked !== false ? true : false
      if (this.showCorrect) this.showCorrect = this.hasAnswer()
      console.warn(this.showCorrect, this.getQuestion.options, answer[0])
      this.loading = false
    },
    async process() {
      // 监控答案变化，0.8秒无操作提交答案
      let isChange = false
      if (this.processi > 8) {
        isChange = JSON.stringify([[this.formData], this.showCorrect]) !== JSON.stringify([this.one.answer, this.one.locked ?? false])
        this.processi = 0
        console.log('check radio change:', isChange)
      }
      if (isChange && !Acan.isEmpty(this.formData)) {
        const item = this.getQuestion.options.find((v) => v._id === this.formData)
        if (item) await this.answerChoice({...this.one}, {content: this.getAnswerText(item), answer: this.formData, locked: this.showCorrect})
        this.loadData()
      } else {
        this.processi++
      }
      setTimeout(this.process, 100)
    },
    changAnswer() {
      this.processi = 0
    },
    changeLocked() {
      this.showCorrect = true
    },
    hasAnswer() {
      return !!this.getQuestion.options.find((o) => o.on)
    },
    getAnswerText(item) {
      let AA = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
      return AA[this.optIds.indexOf(item._id)] + ': ' + item.val
    },
  },
}
</script>
