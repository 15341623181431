<template>
  <div :class="`remark-container ${disable && 't-pad'}`">
    <div class="remark-control" v-if="!disable">
      <el-tooltip content="Audio Comment" placement="top">
        <div @click="audio" class="remark-button-outer">
          <img v-if="(!nextInputType && currentInputType === ModalEventsTypeEnum.AUDIO) || nextInputType === ModalEventsTypeEnum.AUDIO" src="../../assets/picture/voice-button.png" class="remark-button" />
          <img v-else src="../../assets/picture/voice-button-gray.png" class="remark-button" />
        </div>
      </el-tooltip>
      <el-tooltip content="Video Comment" placement="top">
        <div @click="video" class="remark-button-outer">
          <img v-if="(!nextInputType  && currentInputType === ModalEventsTypeEnum.VIDEO) || nextInputType === ModalEventsTypeEnum.VIDEO" src="../../assets/picture/video.png" class="remark-button" />
          <img v-else src="../../assets/picture/video-gray.png" class="remark-button" />
        </div>
      </el-tooltip>
      <el-tooltip content="Text Comment" placement="top">
        <div @click="text" class="remark-button-outer">
          <img v-if="(!nextInputType && currentInputType === ModalEventsTypeEnum.TEXT)  || nextInputType === ModalEventsTypeEnum.TEXT" src="../../assets/picture/new-comment.png" class="remark-button" />
          <img v-else src="../../assets/picture/new-comment-gray.png" class="remark-button" />
        </div>
      </el-tooltip>
    </div>
    <ul class="remark-list" ref="remarklist">
      <!--输入区域item-->
      <li v-if="isEditing" v-loading="editLoading" class="remark-list-item record-item remark-focus">
        <div class="item-header">
          <div @click.stop="cancelRecord" class="delete-button"></div>
        </div>
        <div class="remark-item-content">
          <RecordVideo
            v-if="currentInputType === ModalEventsTypeEnum.VIDEO"
            :onSend="addCommentCb"
            :cancel="cancelRecord"
            :isAniInFixed="false"
            ref="recordVideo"/>
          <RecordAudio
            v-else-if="currentInputType === ModalEventsTypeEnum.AUDIO"
            :onSend="addCommentCb"
            :cancel="cancelRecord"
            :onRecordDone="onRecordDone"
            :isAniInFixed="false"
            ref="recordAudio"/>
          <RecordText
            v-else-if="currentInputType === ModalEventsTypeEnum.TEXT"
            :onSend="addCommentCb"
            :cancel="cancelRecord"
            ref="recordText"/>
        </div>
      </li>
      <li v-for="(item, index) in myPageAnswerList" v-loading="loading[item._id]"
        :class="`remark-list-item ${item.type === 'text' && 'text-item'} ${currentRemarkIndex === index && 'remark-focus'} ${(isEditing || (currentRemarkIndex >= 0 && currentRemarkIndex !== index)) ? 'remark-list-item-gray' : ''}`"
        :key="item._id == -1 ? item.tempid : item._id"
        :ref="currentRemarkIndex === index ? 'activeRef': ''"
        :tabindex="currentRemarkIndex === index ? '0' : ''"
        @click="changeRemarkIndex(index)">
        <div class="item-header">
          <el-popconfirm v-if="item._id != -1" @confirm="deleteItem(item._id, index)" confirm-button-text="OK" cancel-button-text="No,Thanks" icon="el-icon-info" title="Are you sure you want to delete this?">
            <div slot="reference" class="delete-button"></div>
          </el-popconfirm>
          <!--
          <div v-if="item._id != -1" @click.stop="deleteItem(item._id, index)" class="delete-button"></div>
          -->
        </div>
        <div class="remark-item-content">
          <VideoPlayer v-if="Fn.fileType(item.content) === 'video'" controlslist="nodownload" :controls="true" :src="item.content" width="280" height="150"/>
          <AudioPlayer v-else-if="Fn.fileType(item.content) === 'audio'" :url="item.content"/>
          <remarkTextComp @click.stop :onSend="upCommentCb" v-else :item="item" :index="index"/>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { ModalEventsTypeEnum } from "@/socket/socketEvents";
import { mapActions, mapState, mapGetters } from "vuex";
import RecordAudio from "../common/recordAudio.vue";
import RecordVideo from "../common/recordVideo.vue";
import RecordText from '../common/recordText.vue';
import AudioPlayer from '../common/audioPlayer.vue';
import remarkTextComp from './remarkTextComp.vue';
export default {
  props: {
    addResponse: { type: Function },
    disable: { type: Boolean, default: false }
  },
  components:{ RecordVideo, RecordAudio, RecordText, AudioPlayer, remarkTextComp },
  computed: {
    ...mapGetters('student', ['myPageAnswerList']),
    ...mapState({
      currentRemarkIndex: state => state.remark.currentRemarkIndex,
      currentInputType: state => state.remark.currentInputType,
      isViewGroup: state => state.student.isViewGroup,
      currentRemarkOptions: state => state.remark.currentRemarkOptions,
    }),
    isEditing() {
      return !this.disable && this.currentRemarkOptions
    }
  },
  watch: {
    currentRemarkIndex() {
      console.log('currentRemarkIndex', this.currentRemarkIndex)
      if(this.currentRemarkIndex > -1) {
        this.focusIndex()
      }
      this.$forceUpdate()
    },
    currentRemarkOptions() {
      this.$refs.remarklist.scrollTop = 0
    },
  },
  created() {
    this.changeRemarkIndex(-1);
    this.setIsInputing(false);
    this.text();
  },
  data() {
    return {
      editLoading: false,
      loading: {},
      ModalEventsTypeEnum,
      nextInputType: null
    };
  },
  methods: {
    ...mapActions("remark", [
      "changeRemarkInputType",
      "changeRemarkIndex",
      "setIsInputing",
      "setCurrentRemarkOptions",
    ]),
    ...mapActions("student", ["updateAnswerdPage"]),
    focusIndex() {
      this.$nextTick(() => {
        this.$refs.activeRef?.[0]?.focus();
      });
    },
    onRecordDone() {
      this.$refs.remarklist.scrollTop = 0
    },
    audio() {
      this.checkRecording(ModalEventsTypeEnum.AUDIO)
      // this.changeRemarkInputType();
    },
    video() {
       this.checkRecording(ModalEventsTypeEnum.VIDEO)
      // this.changeRemarkInputType();
    },
    text() {
       this.checkRecording(ModalEventsTypeEnum.TEXT)
      // this.changeRemarkInputType(;
    },
    checkRecording(nextInputType) {
      if (this.nextInputType !== nextInputType) {
        this.nextInputType = nextInputType
        this.changeRemarkInputType(this.nextInputType)
        return this.cancelRecord()
      }
      if(this.isEditing) {
        if(this.currentInputType === ModalEventsTypeEnum.AUDIO) {
          this.$refs.recordAudio.done()
        } else if(this.currentInputType === ModalEventsTypeEnum.VIDEO) {
          this.$refs.recordVideo.doneRecord()
        } else if(this.currentInputType === ModalEventsTypeEnum.TEXT) {
          this.$refs.recordText.onInputText()
        }
      } else {
        this.changeRemarkInputType(this.nextInputType)
      }
    },
    async deleteItem(id, index) {
      this.loading[id] = true
      this.$forceUpdate()
      await this.$store.dispatch('student/delResponse', id)
      this.loading[id] = false
      this.$forceUpdate()
      if (index === this.currentRemarkIndex) this.changeRemarkIndex(-1)
    },
    cancelRecord() {
      console.log('cancelRecord')
      this.changeRemarkIndex(-1);
      this.setCurrentRemarkOptions(null);
    },
    async upCommentCb(one, text) {
      if (one.content === text) return
      this.loading[one._id] = true
      await this.$store.dispatch('student/upSertResponse', { _id: one._id, content: text })
      this.loading[one._id] = false
      this.$forceUpdate()
    },
    async addCommentCb(link, page, type) {
      link = link.trim()
      if (this.editLoading || !link) return
      this.editLoading = true
      if(this.currentRemarkOptions && link) {
        console.log('addCommentCb:', link)
        const data = { content: link, point: this.currentRemarkOptions };
        const rs = await this.addResponse(data, page, type)
        console.log('addCommentCb ok', rs?._id)
      }
      this.cancelRecord();
      if(this.nextInputType) {
        this.changeRemarkInputType(this.nextInputType)
        this.nextInputType = null
      }
      this.editLoading = false
    },
  }
};
</script>
<style scoped>
.remark-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* padding-top: 100px; */
  box-sizing: border-box;
  background-color: rgba(211, 220, 230, 1);
  display: flex;
  flex-direction: column;
}
.remark-container.t-pad {
  padding-top: 5px;
}
.remark-control {
  width: 310px;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 6px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 29px;
  /* position: absolute; */
  margin-top: 20px;
  margin-left: 20px;
}
.control-left {
  display: flex;
}
.remark-button-outer {
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  padding: 10px;
}
.remark-button-outer.active {
  background: rgba(21, 195, 154, 0.1);
  border: 1px solid #15c39a;
  opacity: 1;
  border-radius: 4px;
}
.remark-button {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.remark-add-button {
  width: 31px;
  height: 31px;
  cursor: pointer;
}
.delete-button {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background-image: url(../../assets/picture/delete.png);
  background-size: 16px 16px;
  background-position: 9px 9px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.delete-button:hover {
  background-color: tomato;
}
.remark-list {
  padding: 20px;
  width: 350px;
  flex: 1;
  overflow-y: auto;
  box-sizing: border-box;
}
.remark-list-item {
  width: 310px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(214, 214, 214, 1);
  opacity: 1;
  border-radius: 6px;
  margin-bottom: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.remark-list-item.record-item {
  height: auto;
}
.remark-list-item.text-item {
  max-height: 180px;
}
.remark-list-item.remark-focus {
  outline: none !important;
  box-shadow: 0px 0px 2px 2px #15c39a;
}
.remark-list-item.remark-list-item-gray{
  opacity: 0.4;
}
.item-header {
  width: 310px;
  min-height: 45px;
  background: rgba(21, 195, 154, 1);
  opacity: 1;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 18px;
}
.user-icon {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  margin-right: 11px;
  background-color: #eee;
  line-height: 34px;
  text-align: center;
  font-size: 20px;
}
.user-name {
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  opacity: 1;
  text-align: left;
}
.user-time {
  font-size: 10px;
  line-height: 14px;
}
.remark-item-content {
  overflow: auto;
  box-sizing: border-box;
  padding: 1rem;
  flex: 1;
  word-break: break-all;
  position: relative;
}
.remark-text {
  line-height: 24px;
  color: #000000;
  text-align: justify;
}
video {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}
</style>
