<template>
  <div class="page">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="640.004"
      height="123.701"
      viewBox="0 0 640.004 123.701"
    >
      <defs>
        <filter
          id="联合_2"
          x="0"
          y="0"
          width="640.004"
          height="123.701"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="6" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood flood-color="#7d7d7d" flood-opacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="背景" transform="translate(-1693.225 -2012.002)">
        <g class="cls-4" transform="matrix(1, 0, 0, 1, 1693.22, 2012)">
          <g id="联合_2-2" data-name="联合 2" class="cls-1" transform="translate(-17002 -19037)">
            <path
              class="cls-2"
              d="M 17436 19139.19921875 C 17434.54296875 19139.19921875 17433.169921875 19138.55859375 17432.1328125 19137.396484375 L 17416.017578125 19119.349609375 L 17415.869140625 19119.181640625 L 17415.64453125 19119.181640625 L 17023.001953125 19119.181640625 C 17019.966796875 19119.181640625 17017.498046875 19116.71484375 17017.498046875 19113.681640625 L 17017.498046875 19051.998046875 C 17017.498046875 19048.96484375 17019.966796875 19046.498046875 17023.001953125 19046.498046875 L 17621.001953125 19046.498046875 C 17624.03515625 19046.498046875 17626.501953125 19048.96484375 17626.501953125 19051.998046875 L 17626.501953125 19113.681640625 C 17626.501953125 19116.71484375 17624.03515625 19119.181640625 17621.001953125 19119.181640625 L 17456.357421875 19119.181640625 L 17456.1328125 19119.181640625 L 17455.984375 19119.349609375 L 17439.869140625 19137.39453125 C 17438.830078125 19138.55859375 17437.45703125 19139.19921875 17436 19139.19921875 Z"
            />
            <path
              class="cls-3"
              d="M 17436 19138.69921875 C 17437.3125 19138.69921875 17438.5546875 19138.1171875 17439.49609375 19137.0625 L 17455.91015625 19118.681640625 L 17621.001953125 19118.681640625 C 17623.759765625 19118.681640625 17626.001953125 19116.439453125 17626.001953125 19113.681640625 L 17626.001953125 19051.998046875 C 17626.001953125 19049.240234375 17623.759765625 19046.998046875 17621.001953125 19046.998046875 L 17023.001953125 19046.998046875 C 17020.2421875 19046.998046875 17017.998046875 19049.240234375 17017.998046875 19051.998046875 L 17017.998046875 19113.681640625 C 17017.998046875 19116.439453125 17020.2421875 19118.681640625 17023.001953125 19118.681640625 L 17416.091796875 19118.681640625 L 17416.390625 19119.015625 L 17432.505859375 19137.0625 C 17433.447265625 19138.119140625 17434.6875 19138.69921875 17436 19138.69921875 M 17436 19139.69921875 C 17434.462890625 19139.69921875 17432.9296875 19139.041015625 17431.759765625 19137.728515625 L 17415.64453125 19119.681640625 L 17023.001953125 19119.681640625 C 17019.6875 19119.681640625 17016.998046875 19116.99609375 17016.998046875 19113.681640625 L 17016.998046875 19051.998046875 C 17016.998046875 19048.68359375 17019.6875 19045.998046875 17023.001953125 19045.998046875 L 17621.001953125 19045.998046875 C 17624.31640625 19045.998046875 17627.001953125 19048.68359375 17627.001953125 19051.998046875 L 17627.001953125 19113.681640625 C 17627.001953125 19116.99609375 17624.31640625 19119.681640625 17621.001953125 19119.681640625 L 17456.357421875 19119.681640625 L 17440.2421875 19137.728515625 C 17439.0703125 19139.041015625 17437.53515625 19139.69921875 17436 19139.69921875 Z"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  }
};
</script>

<style scoped>
.page {
  width: 100%;
  height: 50px;
}
.cls-1 {
  fill: #fff;
}

.cls-2,
.cls-3 {
  stroke: none;
}

.cls-3 {
  fill: #f4f4f4;
}

.cls-4 {
  filter: url(#联合_2);
}
</style>