<template>
  <div class="parent">
    <el-input type="number" @input="onInputText" v-model="formData"></el-input>
  </div>
</template>
<style scoped>
el-input {
  color: #303133;
  font-family: "PingFang SC";
}

.parent {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
</style>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    answerText: { type: Function },
    addAnswerText: { type: Function },
  },
  data() {
    return {
      sendDelay: null,
      formData: '',
      item: {},
    };
  },
  created(){
  },
  beforeDestroy() {
    this.clearDelay()
  },
  computed: {
    ...mapGetters('student', ['myPageAnswerList']),
  },
  methods: {
    loadData() {
      this.formData = this.myPageAnswerList[0]?.data?.content
    },
    async send() {
      if (!this.myPageAnswerList.id) await this.addAnswerText({ data: { content: this.formData }})
      await this.answerText({...this.myPageAnswerList[0], data: { ...this.myPageAnswerList[0]?.data, content: this.formData }})
      this.loadData()
    },
    clearDelay() {
      if(this.sendDelay) {
        clearTimeout(this.sendDelay)
        this.sendDelay = null
      }
    },
    onInputText() {
      this.clearDelay()
      this.sendDelay = setTimeout(() => {
        this.send()
      }, 300)
    }
  }
};
</script>