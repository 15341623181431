/* eslint-disable no-lonely-if */
/* 白板实例 */
// @ts-ignore
import Text from "./drawText";
import DrawTextItem from "./drawTextItem";

export enum DrawTypeData {
  line = "line",
  draw = "draw",
  text = "text",
  marker = "marker",
  circle = "circle",
  rect = "rect",
  polygon = "polygon",
}

type DrawType = "line" | "draw" | "text" | "marker" | "rect" | "circle" | "polygon";
type onDrawBack = (data: any) => void;
type onDrawTextBack = (data: any) => void;
window.canvasPool = [];
window.drawPool = [];
window.textPool = [];
window.textOptsPool = [];

/**
 *
 * 当前存在的bug：1.第一次删除的内容无法恢复。2.删除后的内容在写入text的时候会被恢复
 */

export default class Draw {
  private el: any;
  private strokeColor = "#02a3ee";
  private lineWidth = 2;
  private canvas: any;
  private cxt: any;
  private sildeId = "";
  private canvasText: any;
  private cxtText: any;
  private fontFamily = "Microsoft YaHei";
  private textString = "";
  private textCloseUrl="";
  private polygonStartPoint = {
    //记录多边形的起始点
    beginX: -1,
    beginY: -1,
  };

  private pointer = {
    beginX: 0,
    beginY: 0,
    endX: 0,
    endY: 0,
  };

  private canvasWidth = 0;
  private canvasHeight = 0;
  private isEarse = false; // 橡皮擦

  //当前被选中的text
  private currentSelectEditableDiv: any;
  private currentMouseOnEditableDeiv = false;
  private currentSelectEditableLeft = 0;
  private currentSelectEditableTop = 0;

  private isMouseMoved = false;

  private drawType: DrawType = "draw";
  private onDrawBack: onDrawBack = () => null;
  private onDrawTextBack: onDrawTextBack = () => null;
  private imageData: any;
  private canTextarea = true;
  private canvasParant: any;
  private textPostion = {
    x: 0,
    y: 0,
  };
  private lastImageData = "";
  private currentIndex = -1;
  private page_id = "";

  private textMouseUpTime=0;

  //为了防止双击的时候先执行mouse down 和mouse up，定义两个timer，延时up down的操作，当双击时取消timer就可以屏蔽up down的操作了。
  private timerDown: any;
  private event: any;
  // private lastMouseDownTime = 0;
  // private lastMouseUpTime = 0;

  private isDrawing = false;
  private isPolygonDrawing = false;//是否绘制多边形
  private hasPolygonDrawing =false;
  private drawed = false; // 是否完成绘画

  private isTextMove = false;


  constructor(el: string, elText: string, canvasW: number, canvasH: number, textCloseUrl: string) {
    this.canvasWidth = canvasW;
    this.canvasHeight = canvasH;

    // // // console.log(el)
    this.el = el;
    this.canvas = document.getElementById(this.el);
    this.cxt = this.canvas.getContext("2d");
    this.canvas.width = this.canvasWidth;
    this.canvas.height = this.canvasHeight;

    this.canvasText = document.getElementById(elText);
    this.cxtText = this.canvasText.getContext("2d");
    this.canvasText.width = this.canvasWidth;
    this.canvasText.height = this.canvasHeight;

    this.canvasParant = this.canvas.parentNode;
    this.textCloseUrl=textCloseUrl;
  }

  resetSize(canvasW: number, canvasH: number) {
    this.canvasWidth = canvasW;
    this.canvasHeight = canvasH;
    this.canvas.width = this.canvasWidth;
    this.canvas.height = this.canvasHeight;

    this.canvasText.width = this.canvasWidth;
    this.canvasText.height = this.canvasHeight;

    //this.restoreImageData(this.imageData);
    this.initByBase64(window.drawPool[this.currentIndex]);;
  }

  init(
    onDrawBack: onDrawBack,
    onDrawTextBack: onDrawTextBack,
    initUrl: string,
    page_id: string,
    sildeId: string
  ) {
    this.canvas.width = this.canvasWidth;
    this.canvas.height = this.canvasHeight;

    this.canvasText.width = this.canvasWidth;
    this.canvasText.height = this.canvasHeight;
    // // // console.log(this.cxt.globalCompositeOperation, 'this.cxt.globalCompositeOperation')
    this.onDrawBack = onDrawBack;
    this.onDrawTextBack = onDrawTextBack;
    this.page_id = page_id;
    this.sildeId = sildeId;

    this.cxt.lineCap = "round";
    this.cxt.lineJoin = "round";
    // this.cxt.shadowBlur = 10;
    this.initCanvasEvent();
    // 绘制缓存数据
    if (initUrl) {
      this.initByBase64(initUrl);
    } else {
      this.saveCanvas(true);
    }

  }

  initCanvasEvent() {
    this.canvas.onmousedown = (event: any) => {
      console.log("canvas on mouse down");

      this.event = event;
      clearTimeout(this.timerDown);
      this.drawed = false;
      this.pointer.beginX = event.layerX;
      this.pointer.beginY = event.layerY;
      if (this.drawType === "draw") {
        this.isDrawing = true;
        this.cxt.beginPath();
        this.cxt.moveTo(this.pointer.beginX, this.pointer.beginY);
      } else if (this.drawType === "line") {
        this.isDrawing = true;
      } else if (this.drawType === "rect") {
        this.isDrawing = true;
      } else if (this.drawType == "circle") {
        this.isDrawing = true;
      } else if (this.drawType == "marker") {
        this.isDrawing = true;

        this.cxt.beginPath();
        this.cxt.moveTo(this.pointer.beginX, this.pointer.beginY);
      } else if (this.drawType == "polygon") {
        this.isPolygonDrawing = true;
        this.saveImageData();
        console.log("hasPolygonDrawing", this.hasPolygonDrawing);
        if (this.polygonStartPoint.beginX == -1 && this.polygonStartPoint.beginY == -1) {
          this.polygonStartPoint.beginX = this.pointer.beginX;
          this.polygonStartPoint.beginY = this.pointer.beginY;
          this.hasPolygonDrawing = false;
        } else if (
          Math.abs(this.polygonStartPoint.beginX - this.pointer.endX) <
            Math.max(this.lineWidth, 10) &&
          Math.abs(this.polygonStartPoint.beginY - this.pointer.endY) < Math.max(this.lineWidth, 10)
        ) {
          setTimeout(()=>{
            console.log("hasPolygonDrawing", this.hasPolygonDrawing);
             if (!this.hasPolygonDrawing) {
               this.polygonDrawEnd();
             }
          },500)
        }
      } else if (this.drawType === "text") {
        if (this.pointer.beginY<this.canvas.height) {
          this.drawText();
          this.drawed = true;
        }
      }
    };
    this.canvas.onmousemove = (event: any) => {
      this.pointer.endX = event.layerX;
      this.pointer.endY = event.layerY;

      if (this.drawType == "draw") {
        if (this.isDrawing) {
          this.drawPath();
          this.drawed = true;
        }
      } else if (this.drawType == "line") {
        if (this.isDrawing) {
          this.drawLine();
          this.drawed = true;
        }
      } else if (this.drawType == "rect") {
        if (this.isDrawing) {
          this.restoreImageData(this.imageData);
          this.drawRect(this.cxt, this.pointer);
          this.drawed = true;
        }
      } else if (this.drawType == "circle") {
        if (this.isDrawing) {
          this.drawCircle();
          this.drawed = true;
        }
      } else if (this.drawType == "marker") {
        if (this.isDrawing) {
          this.drawMarker();
          this.drawed = true;
        }
      } else if (this.drawType == "polygon") {
        if (this.isPolygonDrawing) {
          this.drawPolygon();
        }
      } else if (this.drawType == "text") {
        if (this.isTextMove) {

          this.moveTextDiv(event);
          this.drawed = true;
        }
      }
    };

    this.canvas.mouseleave = (event: any) => {
      this.isDrawing = false;
      this.isTextMove = false;
    };

    this.canvas.onmouseup = (event: any) => {
      console.log("canvas------onmouseup");
      if (this.drawType != "polygon" && this.drawType != "text") {
        this.isDrawing = false;
        this.isTextMove = false;
        if (this.drawed) {
          this.saveCanvas();
          this.drawed = false;
        }
      }else if(this.drawType == "text"){
        this.saveText();
      }
    };
    let _this = this;
    this.canvas.addEventListener(
      "dblclick",
      function () {
        clearTimeout(_this.timerDown);
        console.log("dblclick hasPolygonDrawing", _this.hasPolygonDrawing);

        if (_this.drawType == "polygon" ) {
            //双击结束多边形的绘制

            _this.polygonDrawEnd();
            _this.hasPolygonDrawing=true;

        };
      },
      true
    );
    this.canvas.oncontextmenu = () => {
      this.isDrawing = false;
      this.isTextMove = false;
    };
    document.onmouseup = () => {
      this.isDrawing = false;
      this.isTextMove = false;
    };
  }
//保存文本到画板
  saveText(){

        this.textMouseUpTime += 1;
         console.log("saveText", this.textMouseUpTime);
        let flag = this.drawTextOnCanvasC();
        if (flag) {
          this.saveCanvas();
          this.textMouseUpTime = 0;
        }
  }
//通过其他按钮触发保存文本功能
  saveTextByButton(){
    this.disableEditDiv();
    this.saveText();
  }

  //保存canvas状态
  saveCanvas(f?: boolean) {
    const base64Url = this.canvas.toDataURL("image/png");
    this.drawTextOnCanvas();
    const imageUrl = this.canvasText.toDataURL("image/png");

    this.callBackData(base64Url, imageUrl, f);
    ++this.currentIndex;
    window.drawPool.splice(
      this.currentIndex,
      window.drawPool.length - this.currentIndex,
      base64Url
    );
    window.textOptsPool.splice(
      this.currentIndex,
      window.textOptsPool.length - this.currentIndex,
      this.copyWindowTextPool()
    );

  }

  //移动文本位置
  moveTextDiv(e: any) {
    if (this.currentSelectEditableDiv) {
      let editableDiv = this.currentSelectEditableDiv;
      let left = parseInt(editableDiv.style.left) > 10 ? parseInt(editableDiv.style.left) : 10;
      let top = parseInt(editableDiv.style.top) > 10 ? parseInt(editableDiv.style.top) : 10;
      //console.log(e.layerX, e.layerY, left, this.currentSelectEditableLeft);

      left += e.clientX - this.currentSelectEditableLeft;
      top += e.clientY - this.currentSelectEditableTop;
      editableDiv.style.left = `${left}px`;
      editableDiv.style.top = `${top}px`;
      this.currentSelectEditableLeft = e.clientX;
      this.currentSelectEditableTop = e.clientY;

      let deleteImage = document.getElementById(editableDiv.id + "_close_btn");
      if (deleteImage) {
        deleteImage.style.left = `${left - 10}px`;
        deleteImage.style.top = `${top - 10}px`;
      }
    }
  }

  copyWindowTextPool() {
    var list = [];
    for (let i = 0; i < window.textPool.length; i++) {
      if (window.textPool[i].innerText && window.textPool[i].innerText.length > 0) {
        var item = new DrawTextItem(
          window.textPool[i].page_id,
          window.textPool[i].self_id,
          window.textPool[i].left,
          window.textPool[i].top,
          window.textPool[i].fontSize,
          window.textPool[i].fontFamily,
          window.textPool[i].innerText,
          window.textPool[i].color
        );
        item.id = window.textPool[i].id;
        list.push(item);
      }
    }
    return list;
  }

  initByBase64(base64Url: string) {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = base64Url;
    this.cxt.globalCompositeOperation = "source-over";
    img.onload = () => {
      this.cxt.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      this.cxt.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        this.canvasWidth,
        this.canvasHeight
      );
      console.log("width, height", img.width, img.height, this.canvasWidth, this.canvasHeight);
      this.saveImageData();
      if (this.currentIndex === -1) {
        window.drawPool = [];
        const base64Url = this.canvas.toDataURL("image/png");
        window.drawPool.splice(++this.currentIndex, 0, base64Url);

        window.textOptsPool = [];
        window.textOptsPool.splice(this.currentIndex, 0, this.copyWindowTextPool());
      }
      if (this.isEarse) {
        this.cxt.globalCompositeOperation = "destination-out";
      }
    };
  }

  noresetSelectEditableDiv() {
    if (this.currentSelectEditableDiv) {
      this.currentSelectEditableDiv.setAttribute("contenteditable", false);
      this.currentSelectEditableDiv.style.border = "2px solid #00000000";
      this.currentSelectEditableDiv.style.zIndex = "0";
      var deleteImage = document.getElementById(this.currentSelectEditableDiv.id + "_close_btn");
      if (deleteImage) {
        // console.log('hide delete');
        deleteImage.style.display = "none";
      }
    }
  }

  changeDrawType(drawType: DrawType) {
    this.isEarse = false;
    this.drawType = drawType;
    this.cxt.globalCompositeOperation = "source-over";
    // console.log(drawType)
    if (drawType != DrawTypeData.text) {
      this.noresetSelectEditableDiv();
    }
  }

  changeLineWitdh(width: number) {
    this.lineWidth = width;
  }

  changeColor(color: string) {
    this.strokeColor = color;
    if (this.drawType == "text") {
      if (this.currentSelectEditableDiv) {
        this.currentSelectEditableDiv.style.color = color;
        this.changeEditableDiv();
      }
    }
    // this.cxt.globalCompositeOperation = "source-over";
  }

  disableEditDiv(){
    if (this.currentSelectEditableDiv){
      this.currentSelectEditableDiv.setAttribute("contenteditable", "false");
      if (
        !this.currentSelectEditableDiv.innerText ||
        this.currentSelectEditableDiv.innerText.length < 1
      ) {
        this.deleteEditableDiv(this.currentSelectEditableDiv.id);
      } else {
        this.currentSelectEditableDiv.style.border = "2px solid #00000000";
        this.currentSelectEditableDiv.style.zIndex = "0";
        this.currentSelectEditableDiv.focus();
        this.currentSelectEditableDiv.setAttribute("contenteditable", "false");
        var deleteImage = document.getElementById(this.currentSelectEditableDiv.id + "_close_btn");
        if (deleteImage) deleteImage.style.display = "none";
      }
      this.changeEditableDiv();
      this.currentSelectEditableDiv = null;
    }
  }

  //可以使用两层 canvas
  //上面一层当drawType是text时才显现，用来保存图片的
  //创建一个可以编辑的div
  createEditableDiv(textItem: any) {
    console.log("createEditableDiv", textItem, this.currentSelectEditableDiv);
    if (this.currentSelectEditableDiv && !textItem) {
       if (!this.currentMouseOnEditableDeiv) {
          this.disableEditDiv();
          return ;
       }
    }

    //console.log("createEditableDiv", this.pointer.beginY, this.canvas.height);
    //if (this.pointer.beginY + Math.max(18, this.lineWidth) > this.canvas.height){
      //return ;
    //}
    let editableDiv = document.createElement("div");
    this.canvasParant.appendChild(editableDiv);
    let _this = this;
    this.textMouseUpTime = 0;

    var closeImg = document.createElement("img");

    if (!textItem) {
      var divId = "editable_div_" + this.page_id + "_" + new Date().getTime();
      if (this.pointer.beginY > this.canvasHeight - this.lineWidth) {
        this.pointer.beginY = this.canvasHeight - this.lineWidth;
      }
      console.log(
        "this.pointer.beginY ,canvas.height , this.lineWidth:",
        this.pointer.beginY,
        this.canvas.height,
        this.lineWidth
      );
      textItem = new DrawTextItem(
        this.page_id,
        divId,
        this.pointer.beginX,
        this.pointer.beginY,
        this.lineWidth,
        this.fontFamily,
        editableDiv.innerText + " ",
        this.strokeColor
      );

      this.currentSelectEditableDiv = editableDiv;
      editableDiv.setAttribute("contenteditable", "true");


      this.currentSelectEditableLeft = textItem.left;
      this.currentSelectEditableTop = textItem.top;

      let fontSize = Math.max(18, this.lineWidth) + "px";
      this.cxt.font = `${fontSize}px ${this.fontFamily}`;
      editableDiv.style.wordWrap= "break-word";
      editableDiv.style.wordBreak="break-all";

      editableDiv.style.border = "2px solid #c2d4fd";
      closeImg.style.display = "block";
      setTimeout(() => {
        editableDiv.focus();
      }, 50);
      this.textPostion = { x: this.pointer.beginX, y: this.pointer.beginY };
    } else {
      editableDiv.style.border = "2px solid #c2d4fd00";
      editableDiv.setAttribute("contenteditable", "false");
      closeImg.style.display = "none";
    }

    closeImg.id = textItem.self_id + "_close_btn";
    closeImg.style.position = "absolute";
    closeImg.style.cursor = "pointer";
    if (this.textCloseUrl){
      closeImg.setAttribute("src", this.textCloseUrl);
    }
     //src\assets\picture\closecom.png//C:\Users\Administrator\Desktop\google-slides-web\ppt\src\assets\picture\closecom.png
    closeImg.style.left = `${textItem.left - 20}px`;
    closeImg.style.top = `${textItem.top - Math.max(18, this.lineWidth)/2}px`;
    closeImg.width = 20;
    closeImg.height = 20;

    closeImg.addEventListener("click", function (e) {
      //删除掉这个text item
      _this.changeEditableDiv();
      _this.deleteEditableDiv(editableDiv.id);
    });

    window.textPool.push(textItem);
    editableDiv.id = textItem.self_id;
    editableDiv.style.position = "absolute";
    // editableDiv.style.zIndex = '' + window.textPool.length;
    editableDiv.style.fontFamily = textItem.fontFamily;
    editableDiv.style.background = "#00000000";
    editableDiv.style.color = textItem.color;
    editableDiv.style.outline = "0";
    editableDiv.style.minWidth = "20px";
    editableDiv.style.height = "auto";
    editableDiv.style.fontSize = Math.max(18, textItem.fontSize) + "px";
    editableDiv.style.lineHeight = Math.max(18, textItem.fontSize) + "px";
    editableDiv.style.minHeight = Math.max(18, textItem.fontSize) + "px";
    // console.warn('====', editableDiv.style.width, editableDiv.style.height)
    editableDiv.style.left = `${textItem.left - 10}px`;
    editableDiv.style.top = `${textItem.top }px`;
    editableDiv.style.padding = "10px 5px 10px 5px";
    editableDiv.style.borderRadius = "2px";
    editableDiv.style.textAlign = "left";
    editableDiv.style.userSelect = "none";
    editableDiv.innerText = textItem.innerText;
    editableDiv.setAttribute("contenteditable", "true");

    editableDiv.onmousedown = function (ev: any) {

      if (_this.drawType != "text") {
        return;
      }

      if (_this.currentSelectEditableDiv) {
        if (_this.currentSelectEditableDiv == editableDiv) {
          editableDiv.setAttribute("contenteditable", "true");
        } else {
          _this.currentSelectEditableDiv.style.border = "2px solid #00000000";
          _this.currentSelectEditableDiv.style.zIndex = "0";
          _this.currentSelectEditableDiv.setAttribute("contenteditable", "false");
          var deleteImage = document.getElementById(
            _this.currentSelectEditableDiv.id + "_close_btn"
          );
          if (deleteImage) deleteImage.style.display = "none";
          if (
            !_this.currentSelectEditableDiv.innerText ||
            _this.currentSelectEditableDiv.innerText.length < 1
          ) {
            console.log("text mou -------down");
            _this.deleteEditableDiv(_this.currentSelectEditableDiv.id);
          }
        }
      }
      let e = ev || event;
      _this.currentSelectEditableDiv = editableDiv;
      editableDiv.style.border = "2px solid #c2d4fd";
      editableDiv.style.userSelect = "none";
      editableDiv.style.zIndex = `${999}`;
      _this.currentSelectEditableLeft = e.clientX;
      _this.currentSelectEditableTop = e.clientY;

      //  _this.currentSelectEditableDiv.setAttribute("contenteditable", "true");
      //   _this.currentSelectEditableDiv.focus();

      var deleteImage = document.getElementById(editableDiv.id + "_close_btn");

      if (deleteImage) {
        // console.log("show delete Image")
        deleteImage.style.display = "block";
        deleteImage.style.zIndex = "999";
      }

      _this.isTextMove = true;
    };

    editableDiv.onmouseenter = function (ev) {
     // console.log("editableDiv.onmouseenter  ev", ev);
      if (_this.drawType != "text") {
        return;
      }
      editableDiv.style.cursor = "move";
      _this.currentMouseOnEditableDeiv = true;
      // editableDiv.setAttribute("contenteditable", "true");
    };
    editableDiv.onmouseleave = function (ev) {
      if (_this.drawType != "text") {
        return;
      }
      console.log("onmouseleave", editableDiv.id);
      editableDiv.style.cursor = "default";
      _this.currentMouseOnEditableDeiv = false;

      editableDiv.onmouseup = null;
      editableDiv.setAttribute("contenteditable", "false");
    };

    editableDiv.addEventListener("input", function () {
      //  _this.changeEditableDiv()
    });

    this.canvasParant.appendChild(closeImg);

    return editableDiv.id;
  }

  deleteEditableDiv(_id: any) {
    // console.log('delete', _id);
    var deleteImage = document.getElementById(_id + "_close_btn");
    if (deleteImage)  {this.canvasParant.removeChild(deleteImage);}

    var children = this.canvasParant.children;
    for (let i = 0; i < window.textPool.length; i++) {
      if (window.textPool[i].self_id == _id) {
        window.textPool.splice(i, 1);
        break;
      }
    }
    for (let i = 0; i < children.length; i++) {
      if (children[i].id == _id) {
        this.canvasParant.removeChild(children[i]);
        //children[i].style.display = "none";
        break;
      }
    }
  }

  //改变值
  changeEditableDiv() {
    if (this.currentSelectEditableDiv) {
      for (let i = 0; i < window.textPool.length; i++) {
        if (window.textPool[i].self_id == this.currentSelectEditableDiv.id) {
          // console.log("change text!!!!" + this.currentSelectEditableDiv.innerText);
          window.textPool[i].left = parseInt(this.currentSelectEditableDiv.style.left);
          window.textPool[i].top = parseInt(this.currentSelectEditableDiv.style.top);
          window.textPool[i].color = this.currentSelectEditableDiv.style.color;
          window.textPool[i].fontFamily = this.currentSelectEditableDiv.style.fontFamily;
          window.textPool[i].innerText = this.currentSelectEditableDiv.innerText;
          window.textPool[i].fontSize = this.lineWidth;

          break;
        }
      }
    }
  }

  getLineHeight() {
    // console.log(Math.max(18, this.lineWidth) + "px")
    return Math.max(18, this.lineWidth) + "px";
  }

  // 保留当前画布ImageData
  saveImageData() {
    this.imageData = this.cxt.getImageData(0, 0, this.canvasWidth, this.canvasHeight);

  }

  // 把上一步保留的ImageData绘制到画布上
  restoreImageData(imageData: string) {
    this.cxt.putImageData(imageData, 0, 0);
  }

  setFontFamily(font: string) {
    this.fontFamily = font;
    if (this.currentSelectEditableDiv) {
      this.currentSelectEditableDiv.style.fontFamily = this.fontFamily;
      this.changeEditableDiv();
    }
  }

  drawText() {
    console.log("drawText");
    this.createEditableDiv(null);
  }

  canUndo() {
    return this.currentIndex > 0;
  }
  canRedo() {
    console.log(
      "canRedo ",
      this.currentIndex,
      window.drawPool.length - 1,
      this.currentIndex < window.drawPool.length - 1
    );
    return this.currentIndex < window.drawPool.length - 1;
  }

  //画多边形
  drawPolygon() {
    if (
      Math.abs(this.polygonStartPoint.beginX - this.pointer.endX) < Math.max(this.lineWidth, 10) &&
      Math.abs(this.polygonStartPoint.beginY - this.pointer.endY) < Math.max(this.lineWidth, 10)
    ) {
      this.pointer.endX = this.polygonStartPoint.beginX;
      this.pointer.endY = this.polygonStartPoint.beginY;
    }
    this.drawLine();
  }

  polygonDrawEnd() {
    if(this.isPolygonDrawing) {
      console.log("polygonDrawEnd");
      this.polygonStartPoint.beginY = -1;
      this.polygonStartPoint.beginX = -1;
      this.saveCanvas();
      this.isPolygonDrawing = false;
    }
  }

  // 画椭圆
  drawCircle() {
    this.restoreImageData(this.imageData);
    this.cxt.save();
    this.cxt.beginPath();
    this.cxt.lineWidth = this.lineWidth;
    this.cxt.strokeStyle = this.strokeColor;
    let xR = (this.pointer.endX - this.pointer.beginX) / 2;
    let yR = (this.pointer.endY - this.pointer.beginY) / 2;
    if (xR < 0) {
      this.cxt.moveTo(this.pointer.beginX, this.pointer.beginY + yR);
    } else {
      this.cxt.moveTo(this.pointer.beginX + 2 * xR, this.pointer.beginY + yR);
    }

    let xPoint = this.pointer.beginX + xR;
    let yPoint = this.pointer.beginY + yR;

    if (this.cxt.ellipse) {
      this.cxt.ellipse(xPoint, yPoint, Math.abs(xR), Math.abs(yR), 0, 0, Math.PI * 2);
    } else {
      //使用三次贝塞尔曲线模拟椭圆2
      this.bezierEllipse2(xPoint, yPoint, Math.abs(xR), Math.abs(yR));
    }

    this.cxt.stroke();
    this.cxt.restore();
  }

  bezierEllipse2(x: any, y: any, a: any, b: any) {
    var k = 0.5522848,
      ox = a * k, // 水平控制点偏移量
      oy = b * k; // 垂直控制点偏移量</p> <p> ctx.beginPath();
    //从椭圆的左端点开始顺时针绘制四条三次贝塞尔曲线
    this.cxt.moveTo(x - a, y);
    this.cxt.bezierCurveTo(x - a, y - oy, x - ox, y - b, x, y - b);
    this.cxt.bezierCurveTo(x + ox, y - b, x + a, y - oy, x + a, y);
    this.cxt.bezierCurveTo(x + a, y + oy, x + ox, y + b, x, y + b);
    this.cxt.bezierCurveTo(x - ox, y + b, x - a, y + oy, x - a, y);
    this.cxt.closePath();
    this.cxt.stroke();
  }

  //画矩形
  drawRect(cxt: any, pointer: any) {
    // this.restoreImageData(this.imageData)
    cxt.save();
    cxt.beginPath();
    cxt.lineWidth = this.lineWidth;
    cxt.strokeStyle = this.strokeColor;
    cxt.moveTo(pointer.beginX, pointer.beginY);
    cxt.strokeRect(
      pointer.beginX,
      pointer.beginY,
      pointer.endX - pointer.beginX,
      pointer.endY - pointer.beginY
    );
    cxt.stroke();
    cxt.restore();
  }

  // 画直线
  drawLine() {
    this.restoreImageData(this.imageData);
    this.cxt.save();
    this.cxt.lineJoin = "round";
    this.cxt.strokeStyle = this.strokeColor;
    this.cxt.beginPath();
    this.cxt.lineWidth = this.lineWidth;
    this.cxt.moveTo(this.pointer.beginX, this.pointer.beginY);
    this.cxt.lineTo(this.pointer.endX, this.pointer.endY);

    this.cxt.stroke();
    this.cxt.restore();
  }

  // 画线
  drawPath() {
    this.restoreImageData(this.imageData);
    this.cxt.save();
    this.cxt.lineCap = "round";
    this.cxt.lineJoin = "round";
    this.cxt.lineTo(this.pointer.endX, this.pointer.endY);

    this.cxt.strokeStyle = this.strokeColor;
    this.cxt.lineWidth = this.lineWidth;
    this.cxt.stroke();
    this.cxt.restore();
  }

  // 画标记
  drawMarker() {
    this.restoreImageData(this.imageData);
    this.cxt.strokeStyle = this.strokeColor + "65";
    this.cxt.save();
    this.cxt.lineCap = "round";
    this.cxt.lineJoin = "round";
    this.cxt.lineTo(this.pointer.endX, this.pointer.endY);

    // this.cxt.shadowColor = this.strokeColor;
    this.cxt.lineWidth = this.lineWidth;

    this.cxt.stroke();
    this.cxt.restore();
  }

  addHistory() {
    console.log("====== addHistory");
    const base64Url = this.canvas.toDataURL("image/png");
    window.canvasPool.push(base64Url);
    console.log("canvasPool length" + window.length);
    //保存当前text的
  }

  clearCanvas() {
    console.log("delete!!");
    this.cxt.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.cxtText.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.hindChildren();
    window.textPool = [];

    const base64Url = this.canvas.toDataURL("image/png");
    this.drawTextOnCanvas();
    const imageUrl = this.canvasText.toDataURL("image/png");
    this.callBackData('', imageUrl);

    window.drawPool.splice(0, window.drawPool.length, base64Url);
    window.textOptsPool.splice(0, window.textOptsPool.length, this.copyWindowTextPool());
    this.currentIndex = 0;

    this.polygonStartPoint.beginY = -1;
    this.polygonStartPoint.beginX = -1;
    this.canRedo();
  }

  hindChildren() {
    var e = this.canvasParant.childNodes;
    for (let i = 0; i < e.length; i++) {
      if (e[i].id.indexOf("editable_div") > -1) {
        e[i].style.display = "none";
      }
    }
  }
  //在画板中绘制文字
  drawTextOnCanvasC() {
    let drawTextFlag = false;
    console.log("window.textPool.length", window.textPool.length);
    for (let i = 0; i < window.textPool.length; i++) {
      var item = window.textPool[i];
      var pointer = {
        x: item.left+7,
        y: item.top+11,
        width:this.canvasWidth
      };
      if (
        item.innerText &&
        item.innerText.length > 0 &&
        this.textMouseUpTime >0
      ) {
        console.log("draw -----text", pointer);
        console.log("canvas ****", this.canvas.height);
        const text = new Text(pointer, item.innerText, item.fontSize, item.color, item.fontFamily);
        if (item.innerText.trim().length>0 && pointer.y < this.canvas.height ) {
           text.draw(this.cxt);
           drawTextFlag = true;
        }
        this.deleteEditableDiv(item.self_id);

      }

    }

    return drawTextFlag;
  }

  drawTextOnCanvas() {
    this.cxtText.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    for (let i = 0; i < window.textPool.length; i++) {
      var item = window.textPool[i];
      var pointer = {
        x: item.left,
        y: item.top,
      };
      if (item.innerText && item.innerText.length > 0) {
        const text = new Text(pointer, item.innerText, item.fontSize, item.color, item.fontFamily);
        text.draw(this.cxtText);
      }
    }
  }

  earse() {
    // this.strokeColor = 'pink'
    // this.lineWidth = 20;
    this.isEarse = true;
    this.drawType = "draw";
    this.cxt.globalCompositeOperation = "destination-out";
  }

  //更改undo的逻辑，不采用进出栈的方式，而是采用工作指针的方式
  //这样可以方便的进行撤销操作和撤销撤销的操作
  //
  undo() {
    console.log(window.textOptsPool.length, window.drawPool.length, this.currentIndex, "---");
    if (this.currentIndex > 0) {
      const current = window.drawPool[--this.currentIndex] || "";
      this.initByBase64(current);

      this.resetTextChildren();
      this.drawTextOnCanvas();

      const imageUrl = this.canvasText.toDataURL("image/png");

      this.callBackData(current, imageUrl);
    }
  }
  redo() {
    console.log( window.drawPool.length, this.currentIndex, "+++");
    if (this.currentIndex < window.drawPool.length - 1) {
      const current = window.drawPool[++this.currentIndex] || "";

      this.initByBase64(current);

      this.resetTextChildren();
      this.drawTextOnCanvas();
      const imageUrl = this.canvasText.toDataURL("image/png");

      this.callBackData(current, imageUrl);
    }
  }

  resetTextChildren() {
    //第一步把所有的text 都隐藏掉
    var e = this.canvasParant.childNodes;
    for (let i = 0; i < e.length; i++) {
      if (e[i].id.indexOf("editable_div") > -1) {
        e[i].style.display = "none";
      }
    }
    window.textPool = [];

    //第二步，取出存储的text item
    var textContent = window.textOptsPool[this.currentIndex];
    // // console.log(textContent, "resetTextChildren")
    for (let i = 0; i < textContent.length; i++) {
      var copyItem = new DrawTextItem(
        textContent[i].page_id,
        textContent[i].self_id,
        textContent[i].left,
        textContent[i].top,
        textContent[i].fontSize,
        textContent[i].fontFamily,
        textContent[i].innerText,
        textContent[i].color
      );
      copyItem.id = textContent[i].id;
      window.textPool.push(copyItem);
      for (let j = 0; j < e.length; j++) {
        if (e[j].id == textContent[i].self_id) {
          var editableDiv = e[j];
          e[j].style.display = "block";
          editableDiv.style.left = `${copyItem.left}px`;
          editableDiv.style.top = `${copyItem.top}px`;
          editableDiv.style.fontFamily = copyItem.fontFamily;
          editableDiv.style.background = "#00000000";
          editableDiv.style.border = "2px solid #00000000";
          editableDiv.style.zIndex = "0";
          editableDiv.style.color = copyItem.color;
          editableDiv.style.fontSize = Math.max(18, copyItem.fontSize) + "px";
          editableDiv.style.lineHeight = Math.max(18, copyItem.fontSize) + "px";
          editableDiv.style.minHeight = Math.max(18, copyItem.fontSize) + "px";
          editableDiv.innerText = copyItem.innerText;
          break;
        }
      }
    }
    // // console.log(window.textPool, "resetTextChildren")
  }

  drawAndShareImage(str: string, text: string, f?: boolean) {
    // const dom: any = document.getElementById('canvas')
    // if (!f) this.onDrawBack(!str ? '' : dom.toDataURL("image/png"))
    // return
    const canvas = document.createElement("canvas");
    canvas.width = this.canvasWidth * 1;
    canvas.height = this.canvasHeight * 1;
    const context: any = canvas.getContext("2d");
    context.rect(0, 0, this.canvasWidth * 1, this.canvasHeight * 1);
    context.fillStyle = "rgba(255,255,255,0)";
    context.fill();
    const myImage = new Image();
    myImage.src = str; // 背景图片 你自己本地的图片或者在线图片
    myImage.crossOrigin = "Anonymous";
    myImage.onload = () => {
      context.drawImage(myImage, 0, 0, this.canvasWidth * 1, this.canvasHeight * 1);
      const myImage2 = new Image();
      myImage2.src = text; // 你自己本地的图片或者在线图片
      myImage2.crossOrigin = "Anonymous";
      myImage2.onload = () => {
        context.drawImage(myImage2, 0, 0, this.canvasWidth * 1, this.canvasHeight * 1);
        const base64 = canvas.toDataURL("image/png"); // "image/png" 这里注意一下
        if (!f) this.onDrawBack(!str ? '' : base64)
      };
    };
  }

  callBackData(str: string, text: string, f?: boolean) {
    console.log(str.length, text.length)
    this.drawAndShareImage(str, text, f);
    this.saveImageData();
  }

  callBackDrawText(str: DrawTextItem) {
    this.onDrawTextBack(str);
    // console.log(str, 'send msg ======>>>');
  }

  // addElementTextItem(content: DrawTextItem) {
    // addElementItem(this.sildeId, this.page_id, "text", JSON.stringify(content)).then(
    //   (res: number) => {
    //     content.id = res;
    //     //更新相关item的id
    //     for (let i = 0; i < window.textOptsPool.length; i++) {
    //       var list = window.textOptsPool[i];
    //       for (let j = 0; j < list.length; j++) {
    //         if (list[j].self_id == content.self_id) {
    //           list[j].id = res;
    //         }
    //       }
    //     }
    //     this.updateElementTextItem(content);
    //   }
    // );
  // }

  // deleteElementTextItem(content: DrawTextItem) {
  //   // console.log(content, "deleteElementTextItem")
  //   if (!content.id || content.id < 0) {
  //     // console.log('not commit');
  //     return;
  //   }
  //   // deleteElementItem(content.id).then((res: string) => {
  //   //   // console.log(res);
  //   // });
  // }

  // updateElementTextItem(content: DrawTextItem) {
  //   // console.log(content, "updateElementTextItem");
  //   if (!content.id || content.id < 0) {
  //     if (content.innerText && content.innerText.length > 0) {
  //       this.addElementTextItem(content);
  //     }
  //     return;
  //   }

  //   if (!content.innerText || content.innerText.length < 1) {
  //     this.deleteElementTextItem(content);
  //     return;
  //   }

  //   updateElementItem(content.id, JSON.stringify(content), "text")
  //     .then((res) => {
  //       // console.log(res);
  //     })
  //     .catch((res) => {
  //       // console.log("updata element error!" + res);
  //       this.addElementTextItem(content);
  //     });
  // }

  //在用户关闭或者刷新页面时进行提交操作
  //这时候，window.textOptPool的第一个元素存储了所有的已经在服务器上的text 元素
  //windwo.textPool中存储的是当前的元素，我们需要做一个对比，该更新的更新，该新添的新添，该删除的删除

  // commitTextItem() {
  //   // console.log('do net work commit');
  //   var initialTexts = window.textOptsPool[0]; //最开始从服务器上拉取下来的
  //   var currentTexts = window.textPool; //当前页面上有的
  //   //1 判断是否需要删除
  //   if (initialTexts) {
  //     for (let i = 0; i < initialTexts.length; i++) {
  //       var item = initialTexts[i];
  //       var find = false;
  //       for (let j = 0; j < currentTexts.length; j++) {
  //         if (item.self_id == currentTexts[j].self_id) {
  //           find = true;
  //           break;
  //         }
  //       }
  //       //如果没有找到说明已经被删除了。
  //       if (!find) {
  //         this.deleteElementTextItem(item);
  //       }
  //     }
  //   }

  //   for (let i = 0; i < currentTexts.length; i++) {
  //     this.updateElementTextItem(currentTexts[i]);
  //   }
  // }
}
