<template>
  <div class="temp-container">
    <slot />
    <div class="fileName" v-if="item.file && item.file.name">{{item.file.name}}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fileName: ''
    }
  },
  props: {
    onSuccess: {
      type: Function,
      default: () => null
    },
    item: {
      type: Object,
      default: () => null
    }
  },
  created() {
    if(this.item.file) {
      this.onUpload()
    }
  },
  beforeDestroy() {
    this.cancel()
  },
  methods: {
    async onUpload() {
      this.onSuccess(this.item, await Fn.fileUpLoadUi(this.item.file))
    }
  }
}
</script>
<style scoped>
.temp-container{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.fileName{
  opacity: 0.3;
  width: 100%;
  min-height: 90px;
  position: absolute;
  top: 40px;
  left: 0;
  text-align: center;
  color: #333;
  font-size: 20px;
  display: flex;
  align-items: center;
  line-height: 30px;
}
</style>