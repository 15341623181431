<template>
  <div class="panel flex1">
    <template v-if="isStudentPaced">
      <StudentChangePageButton />
      <StudentTipsModal v-show="!isResponseShow && currentTips.tip" />
    </template>
    <StudentTipsModal v-else />

    <template v-if="!isPageAllLock">
      <div class="flex1 flex_c">
        <div class="checkboxs">
          <el-checkbox :value="currentAnswerd" style="color: #fff"> slide {{ parseInt(currentPageIndex) + 1 }} / {{ getPageNum }} </el-checkbox>
        </div>
      </div>
      <div
        v-if="isStudentPaced && getQuestion && roomGroup"
        class="flex_v flex_c pd_lr05"
        :class="{back_focus: isResponseShow}"
        style="margin-right: 20px"
        @click="toggleResponse">
        <i class="el-icon-s-platform icon2"></i>
        <strong class="button_text">{{ isResponseShow ? 'Hide ' : 'View ' }} Response(s)</strong>
      </div>
      <div class="flex_v flex_c pd_lr05" :class="{back_focus: isMaterialShow}" @click="changeMeterial" v-if="isStudentPaced && getMaterial">
        <div class="">
          <i class="fa icon2" :class="isMaterialShow ? 'fa-eye' : 'fa-eye-slash'" aria-hidden="true"></i>
          <i class="metarialnums">{{ getMaterial.length }}</i>
        </div>
        <strong class="button_text">{{ isMaterialShow ? 'Hide Material(s)' : 'Display material' }}</strong>
      </div>
      <div class="readchatouter" @click="showStudentModal" v-if="!Acan.isEmpty(studentFeedBackComments) && (isStudentPaced || currentFeedList.length)">
        <i class="el-icon-s-comment icon2"></i>
        <i :class="`${hasUnreadFeedback && 'unread'}`"></i>
      </div>
      <!--
      <div class="question_area" v-if="smallWindow" @click="changeShowOrAnswer()">{{ isShowQuestion ? 'Answer ' : 'Show ' }}Question</div>
      <div class="question_area" @click="changeShowOrAnswer()">{{ isShowQuestion ? 'Answer ' : 'Show ' }}Question</div>
      -->
    </template>
  </div>
</template>
<script>
import {mapState, mapGetters} from 'vuex'
import StudentTipsModal from './studentTipsModal.vue'
import StudentChangePageButton from './studentChangePageButton.vue'
export default {
  components: {StudentTipsModal, StudentChangePageButton},
  computed: {
    ...mapGetters(['getQuestion', 'getPageNum', 'getMaterial', 'isMaterialShow', 'isStudentPaced']),
    ...mapGetters('student', ['currentTips', 'roomGroup', 'currentFeedList']),
    ...mapState('student', ['answerdPage', 'currentPageIndex', 'studentFeedBackComments', 'unreadStudentCommentIds']),
    currentAnswerd() {
      return this.answerdPage[this.currentPageIndex]
    },
    hasUnreadFeedback() {
      let unread = false
      this.currentFeedList.forEach((el) => {
        if (this.unreadStudentCommentIds.includes(el._id)) {
          unread = true
        } 
      })

      return unread
    }
  },
  props: {
    isPageAllLock: {type: Boolean, default: false},
    showStudentModal: {type: Function},
    fullScreenWidth: {type: Number, default: 0},
    screenWidth: {type: Number, default: 900},
    isShowQuestion: {type: Boolean, default: true},
    changeShowOrAnswer: {type: Function},
    smallWindow: {type: Boolean, default: false},
    toggleResponse: {type: Function},
    isResponseShow: {type: Boolean, default: false},
    lockallpages: {type: Boolean, default: false},
  },
  data() {
    return {
      questionVisiable: false,
    }
  },
  methods: {
    changeMeterial() {
      if (this.isResponseShow) this.toggleResponse()
      this.$store.dispatch('teacher/showMaterial', [])
    },
  },
  mounted() {},
}
</script>

<style scoped>
/*
@media screen and (max-width: 800px) {
   .question_area {
   }
}
@media screen and (min-width: 800px) {
   .question_area {
      display: none;
   }
}
*/
.question_area {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 150px;
  background-color: cadetblue;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.panel {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.control-bar__button,
.control-bar__button--large,
.control-bar__button--lock {
  display: block;
  position: relative;
  height: auto;
  padding: 0.5em;
  background-color: transparent;
  border: 0px solid transparent;
  opacity: 1;
  text-align: center;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  cursor: pointer;
}
.control-bar__icon,
.control-bar__icon--flip {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 0.9em;
  height: auto;
  fill: #000000;
}

.control-bar__icon:hover {
  fill: #000000;
}

.control-bar__button--large {
  min-width: 4.375em;
  min-height: 60px;
}

.control-bar__label {
  position: relative;
  display: none;
  font-size: 13px;
  color: #ffffff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.icon {
  fill: #ffffff;
  cursor: pointer;
}
.icon:hover {
  fill: antiquewhite;
}
.icon_left {
  margin-left: 20px;
}

.checkboxs {
  height: 100%;
  display: flex;
  align-items: center;
}

.readchatouter {
  margin: 13px 25px;
  position: relative;
  cursor: pointer;
}
.unread {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -4px;
  right: -4px;
  z-index: 100;
  background-color: #ff1a0e;
  border: 2px solid #fff;
  border-radius: 10px;
  box-sizing: border-box;
}
.aligncenter {
  display: flex;
  align-items: center;
}
.back_focus {
  background-color: #15c39a99;
  border-radius: 8px;
}
.dash-tip {
  background-image: url(../../assets/picture/dash-tip.png);
}
.buttondisable {
  opacity: 0.4;
}
</style>
