<template>
  <div class="fullPage" v-bind="$attrs">
    <div class="lock-modal">
      <div class="lock-right">
        <p class="info flex_c">This slide has been locked by your teacher.</p>
        <div class="answer-box" v-if="answerList.length > 0">
          <p class="tips">Your answers:</p>
          <div class="answer-box-inner">
            <template v-if="getQuestion && getQuestion.type != 'website'" >
              <template v-if="['text', 'choice'].includes(getQuestion.type)">
                <div v-for="(item, index) in answerList" :key="index" class="textContent">{{ item.content }}</div>
              </template>
              <template v-if="getQuestion.type == 'media'">
                <div v-for="(item, index) in answerList" :key="index" class="textContent">
                  <dash-right-media-item :item="item"/>
                </div>
              </template>
              <template v-if="getQuestion.type == 'comment'">
                <RemarkList :items="answerList" :showAnswer="false"/>
                <preview-button :previewPress="previewComment" :noSync="true"/>
              </template>
              <template v-if="getQuestion.type == 'draw'"> 
                <div v-for="(item, index) in answerList" :key="index" class="darwAnswer">
                  <Base64image :url="Fn.hashToUrl(getPage.pic || getPage.url)">
                    <Base64image :url="item.content"/>
                  </Base64image>
                </div>
              </template>
            </template> 
          </div>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Base64image from '../base64image.vue';
import DashRightMediaItem from '../teacher/dash-answer/dash-right-media-item.vue';
import DashRightCommentItem from '../teacher/dash-answer/dash-right-comment-item.vue';
import RemarkList from '../teacher/remark/remarkList.vue';
import PreviewButton from '../common/previewButton.vue';
import StudentChangePageButton from './studentChangePageButton.vue';
export default {
  components: {
    Base64image,
    DashRightMediaItem,
    DashRightCommentItem,
    RemarkList,
    PreviewButton,
    StudentChangePageButton
  },
  computed: {
    ...mapGetters(['getPage', 'getQuestion']),
    ...mapGetters('student', ['myPageAnswerList']),
    answerList() {
      return this.myPageAnswerList
    }
  },
  mounted() {},
  methods: {
    ...mapActions("teacher", ["setCurrentPreviewData"]),
    previewComment() {
      const data = { type: 'comment', url: this.answerList }
      this.setCurrentPreviewData(data)
    }
  }
};
</script>


<style  scoped>
.fullPage {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #000000;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
  position: fixed;
  top: 0;
}
.lock-modal{
  width: 80%;
  height: 590px;
  position: relative;
  background-color: #fff;
  border-radius: 10px; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.close-btn{
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
.info{
  font-size: 22px;
  font-weight: 700;
  line-height: 43px;
  color: #05272A
}
.lock-right{
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  padding: 15px 10px 20px 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tips{
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.answer-box{
  width: 100%;
  height: 100%;
}
.answer-box-inner{
  margin-top: 10px;
  height: 470px;
  background: rgba(247, 247, 247, 1);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 14px;
  overflow: scroll;
}
.textContent{
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #B5B5B5;
  opacity: 1;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 24px;
  color: #565656;
}
.darwAnswer{
  width: 100%;
  position: relative;
  height: 100%;
}
</style>
