<template>
<div class="arrow_opts">
  <button class="_button" @click="lastPage">
    <i class="el-icon-arrow-left icon2"></i>
  </button>
  <div class="pageIndex">{{ parseInt(currentPageIndex) + 1 }} of {{ getPageNum }}</div>
  <button class="_button" @click="nextPage">
    <i class="el-icon-arrow-right icon2"></i>
  </button>
</div>
</template>
<script>
import {mapState, mapGetters, mapActions} from 'vuex'
export default {
  computed: {
    ...mapState({
      currentPageIndex: state => state.student.currentPageIndex,
    }),
    ...mapGetters(['getPageNum']),
  },
  methods: {
    ...mapActions("student", [
      "setStudentPageIndex",
    ]),
    pageChange(page) {
      console.log(page, "pageChange", this.currentPageIndex, page);
      const nextPage = parseInt(page);
      if (this.currentPageIndex != nextPage) {
        this.setStudentPageIndex(nextPage);
      } else {
        // console.log("已是当前页码，不用切换", "pageChange");
      }
    },
    lastPage() {
      if (this.currentPageIndex > 0) {
        this.pageChange(this.currentPageIndex - 1);
      }
    },
    nextPage() {
      if (this.currentPageIndex < this.getPageNum - 1) {
        this.pageChange(parseInt(this.currentPageIndex) + 1);
      }
    },
  }
};
</script>
<style scoped>
.arrow_opts {
  display: flex;
  width: auto;
  height: 100%;
}
._button {
  background-color: transparent;
  border: 0px solid transparent;
  cursor: pointer;
}
._button i{color:#2c3e50;}

.pageIndex {
  color: #000;
  font-size: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>